<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-settings</v-icon>在庫状況設定</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-card-subtitle>
          <v-radio-group v-model="makerStock" row label="在庫状況：" hide-details>
            <v-radio label="在庫なし" :value="false"></v-radio>
            <v-radio label="在庫あり" :value="true"></v-radio>
          </v-radio-group>
        </v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-col :style="gridStyle">
            <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 100%"
              :rowData="products"
              :gridOptions="gridOptions"
            ></ag-grid-vue>
          </v-col>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCloseClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onSubmitClick" :disabled="makerStock === null">一括反映</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="bulkErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="在庫状況設定"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { WithIconCellRenderer } from "../../models/ag-grid/cellRenderers";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "ApplyMakerStock",
  components: {
    AgGridVue,
    ErrorGridDialog,
  },
  props: { products: Array, default: () => [] },
  data() {
    return {
      gridStyle: { height: "90%" },
      gridOptions: {
        defaultColDef: {
          filter: false,
          resizable: true,
          sortable: false,
          suppressSizeToFit: true,
        },
        columnDefs: [
          { headerName: "JANCODE", field: "jancode" },
          { headerName: "タイトル", field: "title" },
          { headerName: "商品名", field: "productName" },
          { headerName: "受注単位", field: "unit" },
          {
            headerName: "在庫状況",
            field: "stockStatus",
            valueFormatter: ({ value }) => (value ? "在庫あり" : "在庫なし"),
            cellRenderer: WithIconCellRenderer({ useFormatter: true }),
          },
          { headerName: "備考", field: "makerRemarks" },
        ],
        suppressCsvExport: false,
        suppressExcelExport: true,
        suppressCellSelection: true,
        alwaysShowHorizontalScroll: true,
        enableCellTextSelection: true,
        rowSelection: false,
        pagination: true,
        paginationPageSize: null,
        localeText: AG_GRID_LOCALE_JA,
      },
      makerStock: null,
      errorColmuns: [
        { headerName: "JANCODE", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      updateSuccessRecords: [],
      rules: {
        required: Validation.required,
      },
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 200 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    products(products) {
      this.init(products);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 200 + "px";
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    init(products) {
      this.makerStock = null;
      this.gridOptions.api.setRowData(products);
      this.updateSuccessRecords = [];
    },
    reset() {
      this.init(this.products);
    },
    onCloseClick() {
      this.$emit("onClose", this.updateSuccessRecords);
      this.makerStock = null;
    },
    async onSubmitClick() {
      try {
        this.loadingOn();
        var updateList = [];
        this.errorRows = [];
        let updateRows = [];
        this.gridOptions.api.forEachNode((record) => {
          updateRows.push({
            productCd: record.data.productCd,
            stockStatus: this.makerStock,
            updateDate: record.data.updateDate,
          });
          updateList.push(record.data);
        });
        const response = await this.$store.dispatch("product/stock", { products: updateRows });
        let error = response.data?.header;
        switch (error.resultCode) {
          case ApiStatus.consts.SUCCESS:
          case ApiStatus.consts.ALREADY_CHANGED:
          case ApiStatus.consts.BUSINESS_ERROR:
            // エラーメッセージ格納
            if (error.messages) {
              Object.keys(error.messages).forEach((key) => {
                var errorRecord = updateList.find((record) => record.productCd == key);
                this.errorRows.push({
                  jancode: errorRecord.jancode,
                  errorMessage: error.messages[key],
                });
              });
            }
            // エラー表示
            if (this.errorRows.length > 0) {
              updateRows = [];
              // 登録に成功したデータ(エラーにないデータ)を抽出
              this.gridOptions.api.forEachNode((row) => {
                let isError = this.errorRows.some((r) => r.jancode === row.data.jancode);
                if (!isError) {
                  this.updateSuccessRecords.push(row.data);
                  updateRows.push(row);
                }
              });
              // 更新した分を削除(エラーがある場合のみ)
              this.gridOptions.api.applyTransaction({ remove: updateRows });
              this.$refs.bulkErrorGrid.open({ records: this.errorRows });
              this.$dialog.notify.error(`在庫状況の更新処理に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            } else {
              this.$refs.bulkErrorGrid.close();
              this.$dialog.notify.info(`在庫状況を更新しました`, { timeout: 2300 });
              this.makerStock = null;
              this.$emit("onSubmit", this.updateSuccessRecords);
              this.reset();
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("ApplyMakerStock::onSubmitClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
