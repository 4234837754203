<template>
  <product-image-search></product-image-search>
</template>

<script>
import ProductImageSearch from "./ProductImageSearch.vue";
export default {
  name: "ProductImageEntry",
  components: {
    ProductImageSearch,
  },
};
</script>
