<template>
  <v-container fluid v-if="details">
    <v-card max-height="480">
      <v-carousel height="100%">
        <v-carousel-item v-for="n in imageList" :key="n">
          <v-img contain max-height="480" :lazy-src="n" :src="n"></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-card>
    <v-row no-gutters>
      <v-col v-for="detail in details" :key="detail.name" cols="12" xs="12">
        <detail-card v-if="detail.name" :name="detail.name" :value="detail.value"></detail-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailCard from "../common/DetailCard.vue";

export default {
  name: "ProductDetals",
  props: ["imageList", "details"],
  components: {
    DetailCard,
  },
};
</script>
