<template>
  <v-card>
    <dialog-bar v-if="!isMakerUser" @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn v-if="!isMakerUser" color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                :tooltipShowDelay="tooltipShowDelay"
                :tooltipComponent="tooltipComponent"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>
                {{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。 (一括取込番号：{{
                  importNumber
                }}))
              </p>
              <p v-if="errorRows.length > 0">商品情報の取込エラーがあります。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn v-if="!isMakerUser" color="primary" class="float-right" @click="onComplete()">閉じる</v-btn>
            <v-btn v-if="isMakerUser" color="primary" class="float-right" @click="onComplete()"
              >完了</v-btn
            ></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay v-if="!isMakerUser" :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="商品一括取込"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import FileUtils from "../../utils/FileUtils";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ProductTypes from "../../consts/ProductTypes";
import ProductRanks from "../../consts/ProductRanks";
import ScopeTypes from "../../consts/ScopeTypes";
import { CustomTooltip } from "../../components/ag-grid/toolTips";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "ProductImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data() {
    return {
      step: 1,
      file: null,
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: false,
          filter: false,
          editable: false,
        },
        columnDefs: [],
        rowSelection: false,
        suppressRowClickSelection: true,
        singleClickEdit: true,
        pagination: false,
        localeText: AG_GRID_LOCALE_JA,
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpFullDateColumn: FullDateColumn,
          dpDateColumn: DateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
      },
      importFileFomat: {
        headerCnt: 1,
        details: [
          { fieldName: "商品区分" },
          { fieldName: "カテゴリー" },
          { fieldName: "JANCODE" },
          { fieldName: "pcs有無" },
          { fieldName: "JANCODE(PCS)" },
          { fieldName: "タイトル" },
          { fieldName: "商品名" },
          { fieldName: "商品名カナ" },
          { fieldName: "仕入先略称" },
          { fieldName: "販売元" },
          { fieldName: "発売元" },
          { fieldName: "上代" },
          { fieldName: "掛率" },
          { fieldName: "消費税" },
          { fieldName: "商品説明" },
          { fieldName: "発注締日" },
          { fieldName: "カフェレオ締日" },
          { fieldName: "発売日" },
          { fieldName: "パッケージサイズ幅" },
          { fieldName: "パッケージサイズ奥行" },
          { fieldName: "パッケージサイズ高さ" },
          { fieldName: "PKGsizeW(cm)" },
          { fieldName: "PKGsizeD(cm)" },
          { fieldName: "PKGsizeH(cm)" },
          { fieldName: "メーカー在庫あり" },
          { fieldName: "Box入数" },
          { fieldName: "受注単位" },
          { fieldName: "発注単位" },
          { fieldName: "上限数" },
          { fieldName: "付属商品JANCODE" },
          { fieldName: "情報解禁日" },
          { fieldName: "CT中Box入数" },
          { fieldName: "CT中pcs入数" },
          { fieldName: "CTsizeW" },
          { fieldName: "CTsizeD" },
          { fieldName: "CTsizeH" },
          { fieldName: "食品" },
          { fieldName: "原産国" },
          { fieldName: "賞味期限" },
          { fieldName: "初回締後追加不可" },
          { fieldName: "危険物" },
          { fieldName: "飛行機検査不可" },
          { fieldName: "原材料" },
          { fieldName: "コピーライト" },
          { fieldName: "メーカー備考" },
          { fieldName: "商品ランク" },
          { fieldName: "カフェレオ備考" },
          { fieldName: "販売店公開範囲" },
          { fieldName: "公開販売店" },
          { fieldName: "再販商品" },
        ],
      },
      cafereoColumns: [
        { headerName: "商品区分", field: "productType" },
        { headerName: "カテゴリー", field: "categoryId" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "Box商品", field: "pcsFlg" },
        { headerName: "JANCODE(PCS)", field: "jancodePcs" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        { headerName: "仕入先略称", field: "supplierShortName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "発売元", field: "salesAgencyName" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入価格",
          field: "purchasePrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "消費税",
          field: "consumptionTaxRate",
          type: "dpNumericColumn",
        },
        { headerName: "商品説明", field: "productDetail" },
        {
          headerName: "発注締日",
          field: "orderClosingDate",
        },
        { headerName: "カフェレオ締め日", field: "cafereoClosingDate" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "パッケージサイズ", field: "packageSize" },
        { headerName: "PKGsizeW(cm)", field: "pkgSizeWidth" },
        { headerName: "PKGsizeD(cm)", field: "pkgSizeDepth" },
        { headerName: "PKGsizeH(cm)", field: "pkgSizeHeight" },
        { headerName: "メーカー在庫あり", field: "stockStatus" },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
        },
        {
          headerName: "受注単位",
          field: "orderUnit",
        },
        {
          headerName: "発注単位",
          field: "unit",
        },
        {
          headerName: "上限数",
          field: "maximumQuantity",
        },
        {
          headerName: "付属商品JANCODE",
          field: "accessoriesJanCode",
        },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
        },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
        },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
        },
        {
          headerName: "食品",
          field: "confectionery",
        },
        {
          headerName: "原産国",
          field: "countryOfOrigin",
        },
        {
          headerName: "賞味期限",
          field: "sellBy",
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
        },
        {
          headerName: "危険物",
          field: "hazardousMaterial",
        },
        {
          headerName: "飛行機検査不可",
          field: "airplaneInspection",
        },
        {
          headerName: "原材料",
          field: "rawMaterials",
        },
        { headerName: "コピーライト", field: "copyright" },
        { headerName: "メーカー備考", field: "makerRemarks" },
        { headerName: "商品ランク", field: "productRank" },
        { headerName: "カフェレオ備考", field: "cafereoRemarks" },
        { headerName: "販売店公開範囲", field: "scopeType" },
        { headerName: "取引先名", field: "customerName", tooltipField: "customerName" },
        { headerName: "再販商品フラグ", field: "resaleFlg" },
      ],
      makerColumns: [
        { headerName: "登録日", field: "createDatetime" },
        { headerName: "更新日", field: "updatedDate" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "発注締日", field: "orderClosingDate" },
        { headerName: "情報解禁日", field: "banDatetime" },
        { headerName: "発売元", field: "maker" },
        { headerName: "販売元", field: "customerName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        { headerName: "掛率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "消費税率", field: "consumptionTaxRate", type: "dpNumericColumn" },
        { headerName: "販売単価", field: "salePrice", type: "dpNumericColumn" },
        { headerName: "BOX商品", field: "boxProduct" },
        { headerName: "JANCODE(PCS)", field: "janCodePsc" },
        { headerName: "Box入数", field: "inBoxNumber" },
        { headerName: "受注単位", field: "orderUnit" },
        { headerName: "商品区分", field: "productType" },
        { headerName: "上限数", field: "maximumNumber" },
        { headerName: "初回締後追加不可", field: "nonAdded" },
        { headerName: "カテゴリー", field: "category" },
        { headerName: "商品説明", field: "productDescription" },
        { headerName: "CT中Box入数", field: "inCtBoxNumber" },
        { headerName: "パッケージサイズ", field: "packageSize" },
        { headerName: "CTsizeW", field: "ctSizeWidth" },
        { headerName: "CTsizeD", field: "ctSizeDepth" },
        { headerName: "CTsizeH", field: "ctSizeHeight" },
        { headerName: "食品", field: "confectionery" },
        { headerName: "賞味期限", field: "sellBy" },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection" },
        { headerName: "危険物", field: "hazardousMaterial" },
        { headerName: "原材料", field: "rawMaterials" },
        { headerName: "コピーライト", field: "copyright" },
        { headerName: "備考", field: "remarks" },
      ],
      csvRecords: [],
      importNumber: null,
      validImportForm: null,
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isRate: Validation.isRate,
        isMinNumber: Validation.isMinNumber,
        isJancode: Validation.isJancode,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        isDecimal: Validation.isDecimal,
        releaseDate: Validation.releaseDate,
        isCsv: Validation.isCsv,
        maxRateLength: Validation.maxRateLength,
      },
      importCount: 0,
      errorColmuns: [
        { headerName: "JANCODE", field: "janCode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      tooltipShowDelay: 0,
      tooltipComponent: CustomTooltip,
    };
  },
  beforeMount() {
    if (this.isCafereoUser) this.gridOptions.columnDefs = this.gridOptions.columnDefs.concat(this.cafereoColumns);
    if (this.isVendorUser) this.gridOptions.columnDefs = this.gridOptions.columnDefs.concat(this.vendorColumns);
    if (this.isMakerUser) this.gridOptions.columnDefs = this.gridOptions.columnDefs.concat(this.makerColumns);
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        let formatError = false;
        let lineCnt = 0;
        try {
          this.loadingOn();
          let csvLines = await FileUtils.readAsCsvUtf(this.file);
          this.csvRecords = [];
          if (this.isCafereoUser) {
            csvLines.splice(0, this.importFileFomat.headerCnt);
            lineCnt = this.importFileFomat.headerCnt;
            for (const line of csvLines) {
              lineCnt++;
              if (line.length == this.importFileFomat.details.length) {
                const csvRecord = await this.convertFormatData(line);
                this.csvRecords.push(csvRecord);
              } else {
                formatError = true;
                throw new Error();
              }
            }
          }
          if (this.isMakerUser) {
            var today = new Date();
            for (const [index, line] of csvLines.entries()) {
              // 4行目から取り込む
              if (index >= 3 && line.length > 3) {
                console.log("import");
                console.log(line);
                var recordMaker = {
                  category: line[1],
                  maker: line[2],
                  customerName: line[3],
                  janCode: line[4],
                  retailPrice: line[5],
                  wholesaleRate: line[6],
                  salePrice: line[7],
                  boxProduct: line[8],
                  janCodePsc: line[9],
                  title: line[11],
                  productName: line[12],
                  productNameKana: line[13],
                  copyright: line[14],
                  productDescription: line[15],
                  orderClosingDate: line[16],
                  releaseDate: line[17],
                  packageSize:
                    line[17] && line[19] && line[20] ? line[18] + "×" + line[19] + "×" + line[20] + "mm" : "",
                  inBoxNumber: line[21],
                  orderUnit: line[22],
                  maximumNumber: line[23],
                  banDatetime: line[24],
                  inCtBoxNumber: line[25],
                  ctSizeWidth: line[26],
                  ctSizeDepth: line[27],
                  ctSizeHeight: line[28],
                  confectionery: line[29],
                  consumptionTaxRate: line[29] == "Y" ? "8" : "10",
                  sellBy: line[30],
                  countryOfOrigin: line[31],
                  nonAdded: line[32],
                  hazardousMaterial: line[33],
                  airplaneInspection: line[34],
                  rawMaterials: line[35],
                  remarks: line[36],
                  productType: "1", // 商品区分は通常品固定
                  scopeCustomersName: "N", //TODO 必須チェックにひっかかるため、適当な値を挿入
                  stock: "N", //TODO 必須チェックにひっかかるため、適当な値を挿入
                  createDatetime: today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate(),
                  updatedDate: "",
                };
                this.csvRecords.push(recordMaker);
              }
            }
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          let addMessage = "";
          if (formatError) {
            addMessage = "（ファイルフォーマットエラー：" + lineCnt + "行目）";
          }
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。${addMessage}`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    async convertFormatData(line) {
      if (line.length > 1) {
        var record = {
          productType: line[0],
          categoryId: line[1],
          janCode: line[2],
          pcsFlg: line[3],
          jancodePcs: line[4],
          title: line[5],
          productName: line[6],
          productNameKana: line[7],
          salesAgencyName: line[10],
          salesOriginName: line[9],
          retailPrice: line[11],
          purchaseRate: line[12],
          consumptionTaxRate: line[13],
          productDetail: line[14],
          orderClosingDate: line[15],
          cafereoClosingDate: line[16],
          releaseDate: line[17],
          packageSize: line[18] && line[19] && line[20] ? line[18] + "×" + line[19] + "×" + line[20] + "mm" : "",
          pkgSizeWidth: line[21],
          pkgSizeDepth: line[22],
          pkgSizeHeight: line[23],
          stockStatus: line[24],
          inBoxQuantity: line[25],
          orderUnit: line[26],
          unit: line[27],
          maximumQuantity: line[28],
          accessoriesJanCode: line[29],
          banDatetime: line[30],
          inCtBoxQuantity: line[31],
          inCtPcsQuantity: line[32],
          ctSizeWidth: line[33],
          ctSizeDepth: line[34],
          ctSizeHeight: line[35],
          confectionery: line[36],
          countryOfOrigin: line[37],
          sellBy: line[38],
          nonAdded: line[39],
          hazardousMaterial: line[40],
          airplaneInspection: line[41],
          rawMaterials: line[42],
          copyright: line[43],
          makerRemarks: line[44],
          productRank: line[45],
          cafereoRemarks: line[46],
          scopeType: line[47],
          customerName: customerNames,
          customerCds: customerCds,
          resaleFlg: line[49],
        };
        record.supplierCd = line[8];
        record.supplierShortName = await this.getSupplierShortName(line[8]);
        var customerCds = this.stringArrayFormat(line[48]);
        var customerNames = await this.getCustomerNames(customerCds);
        record.customerCds = customerCds;
        record.customerName = customerNames;
        var purchasePriceRetun = await this.calcPurchasePrice(line[11], line[12], line[8]);
        record.purchasePrice = purchasePriceRetun;
      }
      return record;
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.csvRecords.forEach((record) => {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            janCode: record.janCode,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      });
      if (isValid) {
        try {
          this.errorRows = [];
          this.loadingOn();
          var param = {};
          var apiUrl = "";
          if (this.isCafereoUser) {
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("C040704", requestRecords.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            apiUrl = "product/import";
            param = {
              rows: requestRecords,
              splitNum: 100,
            };
          } else if (this.isMakerUser) {
            apiUrl = "product/makerImport";
            param = {
              orders: requestRecords,
            };
          }
          const response = await this.$store.dispatch(apiUrl, param);

          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              if (this.isCafereoUser) {
                this.importNumber = response.data.contents.importNumber;
                this.importCount = response.data.contents.processCount;
              } else if (this.isMakerUser) {
                this.importNumber = response.data.contents.newProduct.importNumber;
                this.importCount = response.data.contents.newProduct.processCount;
              }
              this.step++;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              if (this.isCafereoUser) {
                this.importNumber = response.data.contents.importNumber;
                this.importCount = response.data.contents.processCount;
              } else if (this.isMakerUser) {
                this.importNumber = response.data.contents.newProduct.importNumber;
                this.importCount = response.data.contents.newProduct.processCount;
              }
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    janCode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (this.errorRows.length > 0) {
                this.$refs.importErrorGrid.open({ records: this.errorRows });
              } else {
                this.$refs.importErrorGrid.close();
              }
              // 画面は完了画面に進める
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      if (this.isCafereoUser) {
        // 商品区分
        this.setValidMessage(this.rules.required(row.productType), "商品区分", messages);
        this.setValidMessage(this.validProductType(row.productType), "商品区分", messages);
        // カテゴリー
        this.setValidMessage(this.rules.required(row.categoryId), "カテゴリー", messages);
        this.setValidMessage(this.rules.maxLength(12)(row.categoryId), "カテゴリー", messages);
        // JANCODE
        this.setValidMessage(this.rules.required(row.janCode), "JANCODE", messages);
        this.setValidMessage(this.rules.isJancode(row.janCode), "JANCODE", messages);
        this.setValidMessage(this.rules.isNumber(row.janCode), "JANCODE", messages);
        // Box商品
        this.setValidMessage(this.isBoolean(row.pcsFlg), "Box商品", messages);
        // JANCODE(PCS)
        this.setValidMessage(this.validPscJan(row.jancodePcs, row.pcsFlg), "JANCODE(PCS)", messages);
        this.setValidMessage(this.rules.isJancode(row.jancodePcs), "JANCODE(PCS)", messages);
        this.setValidMessage(this.rules.isNumber(row.jancodePcs), "JANCODE(PCS)", messages);
        // タイトル
        this.setValidMessage(this.rules.required(row.title), "タイトル", messages);
        this.setValidMessage(this.rules.maxByteLengthSjis(60)(row.title), "タイトル", messages);
        // 商品名
        this.setValidMessage(this.rules.required(row.productName), "商品名", messages);
        this.setValidMessage(this.rules.maxByteLengthSjis(60)(row.productName), "商品名", messages);
        // 商品名カナ
        this.setValidMessage(this.rules.maxLength(30)(row.productNameKana), "商品名カナ", messages);
        // 販売元
        this.setValidMessage(this.rules.maxLength(30)(row.salesOriginName), "販売元", messages);
        // 発売元
        this.setValidMessage(this.rules.required(row.salesAgencyName), "発売元", messages);
        this.setValidMessage(this.rules.maxLength(30)(row.salesAgencyName), "発売元", messages);
        // 上代
        this.setValidMessage(this.rules.required(row.retailPrice), "上代", messages);
        this.setValidMessage(this.rules.isNumber(row.retailPrice), "上代", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.retailPrice), "上代", messages);
        // 仕入価格
        this.setValidMessage(this.rules.required(row.purchasePrice), "仕入価格", messages);
        this.setValidMessage(this.rules.isNumber(row.purchasePrice), "仕入価格", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.purchasePrice), "仕入価格", messages);
        // 仕入掛率
        this.setValidMessage(this.rules.required(row.purchaseRate), "仕入掛率", messages);
        this.setValidMessage(this.rules.isRate(row.purchaseRate), "仕入掛率", messages);
        this.setValidMessage(this.rules.maxLength(5)(row.purchaseRate), "仕入掛率", messages);
        this.setValidMessage(this.rules.maxRateLength(3, 1)(row.purchaseRate), "仕入掛率", messages);
        // 消費税
        this.setValidMessage(this.rules.required(row.consumptionTaxRate), "消費税", messages);
        this.setValidMessage(this.validConsumptionTaxRate(row.consumptionTaxRate), "消費税", messages);
        // 商品説明
        this.setValidMessage(this.rules.maxLength(300)(row.productDetail), "商品説明", messages);
        // 発注締日
        this.setValidMessage(this.rules.required(row.orderClosingDate), "発注締日", messages);
        this.setValidMessage(this.isDate(row.orderClosingDate), "発注締日", messages);
        // カフェレオ締め日
        this.setValidMessage(this.rules.required(row.cafereoClosingDate), "カフェレオ締め日", messages);
        this.setValidMessage(this.isDate(row.cafereoClosingDate), "カフェレオ締め日", messages);
        // 発売日
        this.setValidMessage(this.rules.required(row.releaseDate), "発売日", messages);
        this.setValidMessage(this.rules.releaseDate(row.releaseDate), "発売日", messages);

        this.setValidMessage(this.rules.maxLength(25)(row.packageSize), "パッケージサイズ", messages);
        // PKGsizeW(cm)
        this.setValidMessage(this.rules.isDecimal(row.pkgSizeWidth), "PKGsizeW(cm)", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.pkgSizeWidth), "PKGsizeW(cm)", messages);
        // PKGsizeD(cm)
        this.setValidMessage(this.rules.isDecimal(row.pkgSizeDepth), "PKGsizeD(cm)", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.pkgSizeDepth), "PKGsizeD(cm)", messages);
        // PKGsizeH(cm)
        this.setValidMessage(this.rules.isDecimal(row.pkgSizeHeight), "PKGsizeH(cm)", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.pkgSizeHeight), "PKGsizeH(cm)", messages);
        // メーカー在庫あり
        this.setValidMessage(this.rules.required(row.stockStatus), "メーカー在庫あり", messages);
        this.setValidMessage(this.isBoolean(row.stockStatus), "メーカー在庫あり", messages);
        // Box入数
        this.setValidMessage(this.validInBoxQuantity(row.inBoxQuantity, row.pcsFlg), "Box入数", messages);
        this.setValidMessage(this.rules.isNumber(row.inBoxQuantity), "Box入数", messages);
        this.setValidMessage(this.rules.isMinNumber(1)(row.inBoxQuantity), "Box入数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.inBoxQuantity), "Box入数", messages);
        // 受注単位
        this.setValidMessage(this.rules.required(row.orderUnit), "受注単位", messages);
        this.setValidMessage(this.rules.isNumber(row.orderUnit), "受注単位", messages);
        this.setValidMessage(this.rules.isMinNumber(1)(row.orderUnit), "受注単位", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.orderUnit), "受注単位", messages);
        // 発注単位
        this.setValidMessage(this.rules.required(row.unit), "発注単位", messages);
        this.setValidMessage(this.rules.isNumber(row.unit), "発注単位", messages);
        this.setValidMessage(this.rules.isMinNumber(1)(row.unit), "発注単位", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.unit), "発注単位", messages);
        // 上限数
        this.setValidMessage(this.rules.isNumber(row.maximumQuantity), "上限数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.maximumQuantity), "上限数", messages);
        // 付属商品JANCODE
        this.setValidMessage(this.rules.isJancode(row.accessoriesJanCode), "付属商品JANCODE", messages);
        this.setValidMessage(this.rules.isNumber(row.accessoriesJanCode), "付属商品JANCODE", messages);
        // 情報解禁日
        this.setValidMessage(this.rules.required(row.banDatetime), "情報解禁日", messages);
        this.setValidMessage(this.isDateTime(row.banDatetime), "情報解禁日", messages);
        // CT中Box入数
        this.setValidMessage(this.rules.isNumber(row.inCtBoxQuantity), "CT中Box入数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.inCtBoxQuantity), "CT中Box入数", messages);
        // CT中pcs入数
        this.setValidMessage(this.rules.isNumber(row.inCtPcsQuantity), "CT中pcs入数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.inCtPcsQuantity), "CT中pcs入数", messages);
        // CTsizeW
        this.setValidMessage(this.rules.isDecimal(row.ctSizeWidth), "CTsizeW", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.ctSizeWidth), "CTsizeW", messages);
        // CTsizeD
        this.setValidMessage(this.rules.isDecimal(row.ctSizeWidth), "CTsizeD", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.ctSizeDepth), "CTsizeD", messages);
        // CTsizeH
        this.setValidMessage(this.rules.isDecimal(row.ctSizeHeight), "CTsizeH", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.ctSizeHeight), "CTsizeH", messages);
        // 食品
        this.setValidMessage(this.rules.required(row.confectionery), "食品", messages);
        this.setValidMessage(this.isBoolean(row.confectionery), "食品", messages);
        // 原産国
        this.setValidMessage(this.rules.maxLength(30)(row.countryOfOrigin), "原産国", messages);
        // 賞味期限
        this.setValidMessage(this.validSellBy(row.sellBy, row.confectionery), "賞味期限", messages);
        this.setValidMessage(this.isDate(row.sellBy), "賞味期限", messages);
        // 初回締後追加不可
        this.setValidMessage(this.rules.required(row.nonAdded), "初回締後追加不可", messages);
        this.setValidMessage(this.isBoolean(row.nonAdded), "初回締後追加不可", messages);
        // 危険物
        this.setValidMessage(this.rules.required(row.hazardousMaterial), "危険物", messages);
        this.setValidMessage(this.isBoolean(row.hazardousMaterial), "危険物", messages);
        // 飛行機検査不可
        this.setValidMessage(this.rules.required(row.airplaneInspection), "飛行機検査不可", messages);
        this.setValidMessage(this.isBoolean(row.airplaneInspection), "飛行機検査不可", messages);
        // 原材料
        this.setValidMessage(this.rules.maxLength(30)(row.rawMaterials), "原材料", messages);
        // メーカー備考
        this.setValidMessage(this.rules.maxLength(100)(row.makerRemarks), "メーカー備考", messages);
        // 商品ランク
        this.setValidMessage(this.rules.required(row.productRank), "商品ランク", messages);
        this.setValidMessage(this.validProductRank(row.productRank), "商品ランク", messages);
        // メーカー備考
        this.setValidMessage(this.rules.maxLength(100)(row.makerRemarks), "カフェレオ備考", messages);
        // 販売店公開範囲
        this.setValidMessage(this.rules.required(row.scopeType), "販売店公開範囲", messages);
        this.setValidMessage(this.validScopeType(row.scopeType), "販売店公開範囲", messages);
        // コピーライト
        this.setValidMessage(this.rules.required(row.copyright), "コピーライト", messages);
        this.setValidMessage(this.rules.maxLength(300)(row.copyright), "コピーライト", messages);

        this.setValidMessage(this.rules.maxLength(12)(row.supplierCd), "仕入先CD", messages);

        // 再販商品フラグ
        this.setValidMessage(this.isBoolean(row.resaleFlg), "再販商品フラグ", messages);
      }
      if (this.isMakerUser) {
        // 在庫あり
        this.setValidMessage(this.rules.required(row.stock), "在庫あり", messages);
        this.setValidMessage(this.isBoolean(row.stock), "在庫あり", messages);
        // 発売日
        this.setValidMessage(this.rules.required(row.releaseDate), "発売日", messages);
        this.setValidMessage(this.rules.releaseDate(row.releaseDate), "発売日", messages);
        // 発注締日
        this.setValidMessage(this.rules.required(row.orderClosingDate), "発注締日", messages);
        this.setValidMessage(this.isDate(row.orderClosingDate), "発注締日", messages);
        // 情報解禁日
        this.setValidMessage(this.rules.required(row.banDatetime), "情報解禁日", messages);
        this.setValidMessage(this.isDateTime(row.banDatetime), "情報解禁日", messages);
        // 発売元
        this.setValidMessage(this.rules.required(row.maker), "発売元", messages);
        this.setValidMessage(this.rules.maxLength(30)(row.maker), "発売元", messages);
        // 販売元
        this.setValidMessage(this.rules.required(row.customerName), "販売元", messages);
        this.setValidMessage(this.rules.maxLength(30)(row.customerName), "販売元", messages);
        // JANCODE
        this.setValidMessage(this.rules.required(row.janCode), "JANCODE", messages);
        this.setValidMessage(this.rules.isJancode(row.janCode), "JANCODE", messages);
        // タイトル
        this.setValidMessage(this.rules.required(row.title), "タイトル", messages);
        this.setValidMessage(this.rules.maxByteLengthSjis(60)(row.title), "タイトル", messages);
        // 商品名
        this.setValidMessage(this.rules.required(row.productName), "商品名", messages);
        this.setValidMessage(this.rules.maxByteLengthSjis(60)(row.productName), "商品名", messages);
        // 上代
        this.setValidMessage(this.rules.required(row.retailPrice), "上代", messages);
        this.setValidMessage(this.rules.isNumber(row.retailPrice), "上代", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.retailPrice), "上代", messages);
        // 仕入掛率
        this.setValidMessage(this.rules.required(row.wholesaleRate), "掛率", messages);
        this.setValidMessage(this.rules.isRate(row.wholesaleRate), "掛率", messages);
        this.setValidMessage(this.rules.maxRateLength(3, 1)(row.wholesaleRate), "掛率", messages);
        // 消費税
        this.setValidMessage(this.rules.required(row.consumptionTaxRate), "消費税率", messages);
        this.setValidMessage(this.rules.isRate(row.consumptionTaxRate), "消費税率", messages);
        this.setValidMessage(this.validConsumptionTaxRate(row.consumptionTaxRate), "消費税", messages);
        // 販売単価
        this.setValidMessage(this.rules.required(row.salePrice), "販売単価", messages);
        this.setValidMessage(this.rules.isNumber(row.salePrice), "販売単価", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.salePrice), "販売単価", messages);
        // BOX商品
        this.setValidMessage(this.rules.required(row.boxProduct), "BOX商品", messages);
        this.setValidMessage(this.isBoolean(row.boxProduct), "BOX商品", messages);
        // Box入数
        var pcsFlg = "Y";
        if (row.janCodePsc == null || row.janCodePsc == "") {
          pcsFlg = "N";
        }
        this.setValidMessage(this.rules.isJancode(row.janCodePsc), "JANCODE(PCS)", messages);
        this.setValidMessage(this.validInBoxQuantity(row.inBoxNumber, pcsFlg), "Box入数", messages);
        this.setValidMessage(this.rules.isNumber(row.inBoxNumber), "Box入数", messages);
        this.setValidMessage(this.rules.isMinNumber(1)(row.inBoxNumber), "Box入数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.inBoxNumber), "Box入数", messages);
        // 受注単位
        this.setValidMessage(this.rules.required(row.orderUnit), "受注単位", messages);
        this.setValidMessage(this.rules.isMinNumber(1)(row.orderUnit), "受注単位", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.orderUnit), "受注単位", messages);
        // 商品区分
        this.setValidMessage(this.rules.required(row.productType), "商品区分", messages);
        this.setValidMessage(this.validProductType(row.productType), "商品区分", messages);
        // 販路限定
        this.setValidMessage(this.rules.required(row.scopeCustomersName), "販路限定", messages);
        this.setValidMessage(this.isBoolean(row.scopeCustomersName), "販路限定", messages);
        // 上限数
        this.setValidMessage(this.rules.isNumber(row.maximumNumber), "上限数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.maximumNumber), "上限数", messages);
        // 初回締後追加不可
        this.setValidMessage(this.rules.required(row.nonAdded), "初回締後追加不可", messages);
        this.setValidMessage(this.isBoolean(row.nonAdded), "初回締後追加不可", messages);
        // カテゴリー
        this.setValidMessage(this.rules.required(row.category), "カテゴリー", messages);
        this.setValidMessage(this.rules.maxLength(12)(row.category), "カテゴリー", messages);

        this.setValidMessage(this.rules.maxLength(300)(row.productDescription), "商品説明", messages);

        // CT中Box入数
        this.setValidMessage(this.rules.isNumber(row.inCtBoxNumber), "CT中Box入数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.inCtBoxNumber), "CT中Box入数", messages);

        this.setValidMessage(this.rules.maxLength(25)(row.packageSize), "パッケージサイズ", messages);
        // CTsizeW
        this.setValidMessage(this.rules.isDecimal(row.ctSizeWidth), "CTsizeW", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.ctSizeWidth), "CTsizeW", messages);
        // CTsizeD
        this.setValidMessage(this.rules.isDecimal(row.ctSizeWidth), "CTsizeD", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.ctSizeDepth), "CTsizeD", messages);
        // CTsizeH
        this.setValidMessage(this.rules.isDecimal(row.ctSizeHeight), "CTsizeH", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.ctSizeHeight), "CTsizeH", messages);
        // 食品
        this.setValidMessage(this.rules.required(row.confectionery), "食品", messages);
        this.setValidMessage(this.isBoolean(row.confectionery), "食品", messages);
        // 賞味期限
        this.setValidMessage(this.validSellBy(row.sellBy, row.confectionery), "賞味期限", messages);
        this.setValidMessage(this.isDate(row.sellBy), "賞味期限", messages);
        // 原産国
        this.setValidMessage(this.validSellBy(row.countryOfOrigin, row.confectionery), "原産国", messages);
        this.setValidMessage(this.rules.maxLength(30)(row.countryOfOrigin), "原産国", messages);
        // 飛行機検査不可
        this.setValidMessage(this.rules.required(row.airplaneInspection), "飛行機検査不可", messages);
        this.setValidMessage(this.isBoolean(row.airplaneInspection), "飛行機検査不可", messages);
        // 危険物
        this.setValidMessage(this.rules.required(row.hazardousMaterial), "危険物", messages);
        this.setValidMessage(this.isBoolean(row.hazardousMaterial), "危険物", messages);
        // 原材料
        this.setValidMessage(this.rules.maxLength(30)(row.rawMaterials), "原材料", messages);
        // コピーライト
        this.setValidMessage(this.rules.required(row.copyright), "コピーライト", messages);
        this.setValidMessage(this.rules.maxLength(300)(row.copyright), "コピーライト", messages);

        this.setValidMessage(this.rules.maxLength(100)(row.remarks), "備考", messages);
      }

      if (messages.length > 0) ret = messages;
      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    requestFormat(row) {
      if (this.isCafereoUser) {
        return {
          productType: this.numberFormat(row.productType),
          title: this.stringFormat(row.title),
          categoryId: this.stringFormat(row.categoryId),
          jancode: this.stringFormat(row.janCode),
          boxProduct: this.booleanFormat(row.pcsFlg),
          jancodePcs: this.stringFormat(row.jancodePcs),
          productName: this.stringFormat(row.productName),
          productNameKana: this.stringFormat(row.productNameKana),
          supplierCd: this.stringFormat(row.supplierCd),
          salesOriginName: this.stringFormat(row.salesOriginName),
          salesAgencyName: this.stringFormat(row.salesAgencyName),
          retailPrice: this.numberFormat(row.retailPrice),
          purchasePrice: this.numberFormat(row.purchasePrice),
          purchaseRate: this.numberFormat(row.purchaseRate),
          consumptionTaxRate: this.numberFormat(row.consumptionTaxRate),
          productDetail: this.stringFormat(row.productDetail),
          orderClosingDate: this.stringDateFormat(row.orderClosingDate),
          releaseDate: this.stringFormat(row.releaseDate),
          packageSize: this.stringFormat(row.packageSize),
          pkgSizeWidth: this.numberFormat(row.pkgSizeWidth),
          pkgSizeDepth: this.numberFormat(row.pkgSizeDepth),
          pkgSizeHeight: this.numberFormat(row.pkgSizeHeight),
          inBoxQuantity: this.defaultNumberFormat(row.inBoxQuantity, 1),
          stockStatus: this.booleanFormat(row.stockStatus),
          orderUnit: this.numberFormat(row.orderUnit),
          maximumQuantity: this.numberFormat(row.maximumQuantity),
          banDatetime: this.stringDateTimeFormat(row.banDatetime),
          cafereoClosingDate: this.stringDateFormat(row.cafereoClosingDate),
          accessoriesJanCode: this.stringFormat(row.accessoriesJanCode),
          inCtBoxQuantity: this.numberFormat(row.inCtBoxQuantity),
          inCtPcsQuantity: this.numberFormat(row.inCtPcsQuantity),
          ctSizeWidth: this.numberFormat(row.ctSizeWidth),
          ctSizeDepth: this.numberFormat(row.ctSizeDepth),
          ctSizeHeight: this.numberFormat(row.ctSizeHeight),
          confectionery: this.booleanFormat(row.confectionery),
          countryOfOrigin: this.stringFormat(row.countryOfOrigin),
          sellBy: this.stringDateFormat(row.sellBy),
          nonAdded: this.booleanFormat(row.nonAdded),
          hazardousMaterial: this.booleanFormat(row.hazardousMaterial),
          rawMaterials: this.stringFormat(row.rawMaterials),
          makerRemarks: this.stringFormat(row.makerRemarks),
          productRank: this.stringFormat(row.productRank),
          unit: this.numberFormat(row.unit),
          cafereoRemarks: this.stringFormat(row.cafereoRemarks),
          scopeType: this.numberFormat(row.scopeType),
          copyright: this.stringFormat(row.copyright),
          scopedCustomers: row.customerCds,
          resaleFlg: this.booleanFormat(row.resaleFlg),
        };
      }
      if (this.isMakerUser) {
        console.log(row.consumptionTaxRate);
        return {
          stockStatus: this.booleanFormat(row.stock),
          createDatetime: this.stringDateFormat(row.createDatetime),
          updatedDate: this.stringDateFormat(row.updatedDate),
          releaseDate: this.stringFormat(row.releaseDate),
          orderClosingDate: this.stringDateFormat(row.orderClosingDate),
          banDatetime: this.stringDateTimeFormat(row.banDatetime),
          salesAgencyName: this.stringFormat(row.maker),
          salesOriginName: this.stringFormat(row.customerName),
          janCode: this.stringFormat(row.janCode),
          title: this.stringFormat(row.title),
          productName: this.stringFormat(row.productName),
          productNameKana: this.stringFormat(row.productNameKana),
          retailPrice: this.defaultNumberFormat(row.retailPrice, 0),
          purchaseRate: this.percentFormat(row.wholesaleRate, 0),
          consumptionTaxRate: this.percentFormat(row.consumptionTaxRate, 0),
          purchasePrice: this.defaultNumberFormat(row.salePrice, 0),
          boxProduct: this.booleanFormat(row.boxProduct),
          janCodePcs: this.stringFormat(row.janCodePsc),
          inBoxQuantity: this.defaultNumberFormat(row.inBoxNumber, 1),
          unit: this.defaultNumberFormat(row.orderUnit, 0),
          productType: this.defaultNumberFormat(row.productType, 0),
          scopeCustomersName: this.booleanFormat(row.scopeCustomersName),
          maximumQuantity: this.defaultNumberFormat(row.maximumNumber, 0),
          nonAdded: this.booleanFormat(row.nonAdded),
          categoryId: this.stringFormat(row.category),
          productDetail: this.stringFormat(row.productDescription),
          inCtBoxQuantity: this.defaultNumberFormat(row.inCtBoxNumber, 0),
          packageSize: this.stringFormat(row.packageSize),
          ctSizeWidth: this.defaultNumberFormat(row.ctSizeWidth, 0),
          ctSizeDepth: this.defaultNumberFormat(row.ctSizeDepth, 0),
          ctSizeHeight: this.defaultNumberFormat(row.ctSizeHeight, 0),
          confectionery: this.booleanFormat(row.confectionery),
          sellBy: this.stringDateFormat(row.sellBy),
          countryOfOrigin: this.stringFormat(row.countryOfOrigin),
          airplaneInspection: this.booleanFormat(row.airplaneInspection),
          hazardousMaterial: this.booleanFormat(row.hazardousMaterial),
          rawMaterials: this.stringFormat(row.rawMaterials),
          copyright: this.stringFormat(row.copyright),
          makerRemarks: this.stringFormat(row.remarks),
        };
      }
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isDateTime(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY/MM/DD HH:mm", true).isValid()) return "YYYY/MM/DD HH:mm形式で入力してください";
      return true;
    },
    isBoolean(value) {
      if (value == null || value == "") return true;
      if (value != "Y" && value != "N") return "YまたはNで入力してください";
      return true;
    },
    validProductType(value) {
      if (value == null || value == "") return true;
      if (ProductTypes.of(value) == null) return "登録可能な値を入力してください";
      return true;
    },
    validPscJan(value, pcsFlg) {
      if (pcsFlg != "Y") return true;
      if (!(this.rules.required(value) === true)) return "pcs有の時は必須入力項目です";
      return true;
    },
    validInBoxQuantity(value, pcsFlg) {
      if (pcsFlg != "Y") return true;
      if (!(this.rules.required(value) === true)) return "pcs有の時は必須入力項目です";
      return true;
    },
    validSellBy(value, confectionery) {
      if (confectionery != "Y") return true;
      if (!(this.rules.required(value) === true)) return "食品有の時は必須入力項目です";
      return true;
    },
    validProductRank(value) {
      if (value == null || value == "") return true;
      if (ProductRanks.of(value) == null) return "登録可能な値を入力してください";
      return true;
    },
    validScopeType(value) {
      if (value == null || value == "") return true;
      if (ScopeTypes.of(value) == null) return "登録可能な値を入力してください";
      return true;
    },
    validConsumptionTaxRate(value) {
      if (value == null || value == "") return true;
      if (isNaN(Number(value)) || !(Number(value) == 0 || Number(value) == 8 || Number(value) == 10))
        return "0,8,10のいずれかを入力してください";
      return true;
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      return this.defaultNumberFormat(value, null);
    },
    defaultNumberFormat(value, defaultValue) {
      if (value === null || value === "" || isNaN(Number(value))) return defaultValue;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(value).format("YYYY-MM-DD");
    },
    stringDateTimeFormat(value) {
      if (value == null || value == "") return "";
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    booleanFormat(value) {
      if (value == null || value == "") return false;
      if (value == "Y") return true;
      if (value == "N") return false;
      return Boolean(value);
    },
    stringArrayFormat(value) {
      if (value == null || value == "") return [];
      return value.split(":");
    },
    percentFormat(value, defaultValue) {
      if (value == null || value == "") return defaultValue;
      return Number(value.replace("%", ""));
    },
    async calcPurchasePrice(retailPrice, purchaseRate, supplierCd) {
      if (isNaN(Number(retailPrice)) || isNaN(parseFloat(purchaseRate)) || !supplierCd) return null;
      if (Number(retailPrice) === 0 && parseFloat(purchaseRate) === 0) return 0;
      const response = await this.$store.dispatch("product/calcPurchasePrce", {
        retailPrice: retailPrice,
        purchaseRate: purchaseRate,
        supplierCd: supplierCd,
      });
      return response.data.contents.purchasePrice;
    },
    async getCustomerNames(customerCds) {
      var customerNames = [];
      for (const customerCd of customerCds) {
        const response = await this.$store.dispatch("customer/getCustomerName", customerCd);
        if (!ApiStatus.isSystemError(response.data?.header)) {
          customerNames.push(response.data.contents.customerName);
        } else {
          customerNames.push("");
        }
      }
      return customerNames;
    },
    async getSupplierShortName(supplierCd) {
      var supplierShortName = "";
      const response = await this.$store.dispatch("supplier/search", { supplierCode: supplierCd });
      if (!ApiStatus.isSystemError(response.data?.header)) {
        var suppliers = response.data.contents.suppliers;
        if (suppliers.length > 0) {
          supplierShortName = suppliers[0].maker;
        }
      }
      return supplierShortName;
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
