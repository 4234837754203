import { createDefault } from "../../utils/ConstUtils";

const cafereoProductBulkEditColumns = [
  { value: 1, text: "商品区分", enum: "PRODUCT_TYPE" },
  { value: 2, text: "カテゴリー", enum: "CATEGORY_NAME" },
  { value: 3, text: "タイトル", enum: "TITLE" },
  { value: 4, text: "発売元", enum: "SALES_AGENCY_NAME" },
  { value: 5, text: "販売元", enum: "SALES_ORIGIN_NAME" },
  { value: 6, text: "上代", enum: "RETAIL_PRICE" },
  { value: 7, text: "仕入掛率", enum: "PURCHASE_RATE" },
  { value: 8, text: "消費税", enum: "CONSUMPTION_TAX_RATE" },
  { value: 9, text: "商品説明", enum: "PRODUCT_DETAIL" },
  { value: 11, text: "発注締日", enum: "CAFEREO_CLOSING_DATE" },
  { value: 12, text: "発売日", enum: "RELEASE_DATE" },
  { value: 13, text: "情報解禁日時", enum: "BAN_DATETIME" },
  { value: 14, text: "メーカー在庫あり", enum: "STOCK_STATUS" },
  { value: 15, text: "受注単位", enum: "ORDER_UNIT" },
  { value: 16, text: "発注単位", enum: "UNIT" },
  { value: 17, text: "商品ランク", enum: "PRODUCT_RANK" },
  { value: 18, text: "カフェレオ備考", enum: "CAFEREO_REMARKS" },
  { value: 19, text: "販売店公開範囲", enum: "SCOPE_TYPE" },
  { value: 20, text: "CT中Box入数", enum: "IN_CT_BOX_QUANTITY" },
  { value: 21, text: "CT中pcs入数", enum: "IN_CT_PCS_QUANTITY" },
  { value: 22, text: "再販前 上代", enum: "RESALE_RETAIL_PRICE" },
  { value: 23, text: "再販前 仕入掛率", enum: "RESALE_PURCHASE_RATE" },
  { value: 24, text: "再販前 商品説明", enum: "RESALE_PRODUCT_DETAIL" },
  { value: 25, text: "再販前 発注締日", enum: "RESALE_CAFEREO_CLOSING_DATE" },
  { value: 26, text: "再販前 発売日", enum: "RESALE_RELEASE_DATE" },
  { value: 27, text: "再販前 情報解禁日時", enum: "RESALE_BAN_DATETIME" },
  { value: 28, text: "再販前 受注単位", enum: "RESALE_ORDER_UNIT" },
  { value: 29, text: "再販前 発注単位", enum: "RESALE_UNIT" },
];
export const CafereoProductBulkEditColumns = createDefault(cafereoProductBulkEditColumns);

const supplierProductBulkEditColumns = [
  { value: 1, text: "商品区分", enum: "PRODUCT_TYPE" },
  { value: 2, text: "カテゴリー", enum: "CATEGORY_NAME" },
  { value: 3, text: "タイトル", enum: "TITLE" },
  { value: 4, text: "発売元", enum: "SALES_AGENCY_NAME" },
  { value: 5, text: "販売元", enum: "SALES_ORIGIN_NAME" },
  { value: 6, text: "消費税", enum: "CONSUMPTION_TAX_RATE" },
  { value: 7, text: "商品説明", enum: "PRODUCT_DETAIL" },
  { value: 8, text: "発注締日", enum: "CAFEREO_CLOSING_DATE" },
  { value: 9, text: "発売日", enum: "RELEASE_DATE" },
  { value: 10, text: "情報解禁日時", enum: "BAN_DATETIME" },
  { value: 11, text: "受注単位", enum: "UNIT" },
];
export const SupplierProductBulkEditColumns = createDefault(supplierProductBulkEditColumns);

export default createDefault(cafereoProductBulkEditColumns, supplierProductBulkEditColumns);
