<template>
  <product-search :linkId="linkId"></product-search>
</template>

<script>
import ProductSearch from "./ProductSearch.vue";
export default {
  name: "ProductRegisteredSearch",
  props: ["linkId"],
  components: {
    ProductSearch,
  },
};
</script>
