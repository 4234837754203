<template>
  <div class="custom-tooltip" v-bind:style="{ backgroundColor: color }">
    <p>
      <span>{{ data.customerName }}</span>
    </p>
  </div>
</template>

<script>
import VueJS from "vue/dist/vue.js";

export default VueJS.extend({
  data: function () {
    return {
      color: null,
      customerName: null,
    };
  },
  beforeMount() {
    this.data = this.params.api.getDisplayedRowAtIndex(this.params.rowIndex).data;
    this.color = this.params.color || "white";
  },
});
</script>
