<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-pencil-outline</v-icon>注文票情報</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-row style="height: 95%" class="mt-4 ml-6">
          <v-row>
            <v-col cols="2"
              ><v-card elevation="1"
                ><v-card-text class="font-weight-bold ml-4 mb-4"
                  >発注数量合計：{{ this.orderQuantityTotal | comma }}</v-card-text
                ></v-card
              ></v-col
            ><v-col cols="2"
              ><v-card elevation="1"
                ><v-card-text class="font-weight-bold ml-4 mb-4"
                  >発注合計金額：￥{{ this.orderUnitPriceTotal | comma }}</v-card-text
                ></v-card
              ></v-col
            ></v-row
          >
        </v-row>
        <v-card-subtitle style="display: flex; height: 40px">
          <v-spacer></v-spacer>
          <tooltip-icon-button
            :disabled="selectionRows.length === 0"
            icon="mdi-trash-can-outline"
            @click="onRemoveClick"
            >削除</tooltip-icon-button
          >
        </v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-col :style="gridStyle">
            <ag-grid-vue
              id="CartList"
              class="ag-theme-alpine"
              style="height: 100%"
              v-model="orderRecords"
              :gridOptions="gridOptions"
              @cellValueChanged="onCellValueChanged"
              @filter-changed="calcTotalItem"
            >
            </ag-grid-vue>
          </v-col>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCloseClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onSubmitClick" :disabled="orderRecords.length === 0">発注登録</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="createErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="注文票情報"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableCheckBoxColumn,
} from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import { CheckboxCellEditor, AutoCompleteCellEditor } from "../../components/ag-grid/cellEditors";
import { comma } from "../../filter/NumberFilter";

export default {
  name: "ProductCart",
  props: {
    initialOrders: { type: Array, default: null },
    shareOrderRecords: { type: Array, default: null },
    deliveries: { type: Array, default: null },
  },
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    AutoCompleteCellEditor,
  },
  data() {
    const deliveries = this.deliveries;
    return {
      gridStyle: { height: "90%" },
      gridOptions: {
        frameworkComponents: {
          dpCheckboxCellEditor: CheckboxCellEditor,
          dpAutoCompleteCellEditor: AutoCompleteCellEditor,
        },
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpEditableColumn: EditableColumn,
          dpEditableCheckBoxColumn: EditableCheckBoxColumn,
        },
        defaultColDef: {
          filter: false,
          resizable: true,
          sortable: false,
          suppressSizeToFit: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerName: "",
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          { headerName: "No", field: "number" },
          { headerName: "JANCODE", field: "jancode" },
          { headerName: "タイトル", field: "title" },
          { headerName: "商品名", field: "productName" },
          { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
          { headerName: "卸掛率", field: "orderRate", type: "dpPercentColumn" },
          { headerName: "卸価格", field: "unitPrice", type: "dpNumericColumn" },
          { headerName: "数量", field: "orderQuantity", type: "dpNumericColumn,dpEditableColumn" },
          { headerName: "単位", field: "orderUnit", type: "dpNumericColumn" },
          {
            headerName: "Box入数",
            field: "inBoxQuantity",
            type: "dpNumericColumn",
          },
          { headerName: "発売日", field: "releaseDate" },
          // {
          //   headerName: "配送CD",
          //   field: "deliveryCode",
          //   type: "dpEditableColumn",
          //   cellEditor: "agSelectCellEditor",
          //   cellEditorParams: () => ({ values: this.deliveries }),
          // },
          {
            headerName: "届先名称",
            field: "deliveryCode",
            type: "dpEditableColumn",
            valueFormatter: (params) => {
              let ret = null;
              console.log(params);
              if (params.value) {
                const item = this.deliveries.find((v) => v.value === params.value);
                return item ? item.label : "";
              } else {
                ret = params.value;
              }
              return ret;
            },
            cellEditorFramework: "AutoCompleteCellEditor",
            cellEditorParams: {
              cellEditorItems: deliveries,
              cellEditorValueId: "value",
              cellEditorTextId: "label",
              cellEditorMultiple: false,
            },
          },
          {
            headerName: "届先法人名",
            field: "deliveryName1",
            valueGetter: ({ data }) => this.deliveryMap[data?.deliveryCode]?.delivery1 || "",
          },
          // {
          //   headerName: " 届先名称",
          //   field: "deliveryName2",
          //   valueGetter: ({ data }) => this.deliveryMap[data?.deliveryCode]?.delivery2 || "",
          // },
          {
            headerName: "取引先専用番号",
            field: "partnerPurchaseNo",
            type: "dpEditableColumn",
          },
          {
            headerName: "取引先専用明細番号",
            field: "partnerDetailNo",
            type: "dpNumericColumn,dpEditableColumn",
          },

          {
            headerName: "フォーキャスト指定",
            field: "forcast",
            cellEditor: "dpCheckboxCellEditor",
            editable: (patrams) => {
              return patrams.data.forecast;
            },
            cellStyle: (patrams) => {
              if (patrams.data.forecast) {
                return {
                  backgroundColor: "#FFEDB3",
                };
              } else {
                return {};
              }
            },
            type: "dpCheckmarkColumn",
          },
        ],
        suppressCsvExport: false,
        suppressExcelExport: true,
        alwaysShowHorizontalScroll: true,
        rowSelection: "multiple",
        pagination: true,
        paginationPageSize: null,
        suppressColumnVirtualisation: true,
        singleClickEdit: true,
        localeText: AG_GRID_LOCALE_JA,
        onSelectionChanged: () => {
          this.selectionRows = this.gridOptions.api.getSelectedRows();
          this.selectedRow = this.selectionRows.length === 1 ? this.selectionRows[0] : null;
        },
        getRowNodeId: (data) => {
          return data.rowId;
        },
      },
      orderQuantityTotal: 0,
      orderUnitPriceTotal: 0,
      orderRecords: [],
      selectedRow: null,
      deliveryMap: {},
      errorColmuns: [
        { headerName: "No", field: "number" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
        maxLength: Validation.maxLength,
      },
      rowId: 1,
      selectionRows: [],
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  async mounted() {
    const customerCode = this.$store.getters["security/loggedInUser"].groupCode;
    const response = await this.$store.dispatch("deliveryDestination/searchDelivery", {
      groupCode: customerCode,
    });
    if (!ApiStatus.isSystemError(response.data?.header)) {
      const deliveries = response.data.contents?.deliveries;
      deliveries.forEach((d) => {
        //this.deliveries.push({ value: d.deliveryCode, label: d.delivery2 });
        this.deliveryMap[d.deliveryCode] = d;
      });
    }
    if (Array.isArray(this.shareOrderRecords) && this.shareOrderRecords.length > 0) {
      this.orderRecords = JSON.parse(JSON.stringify(this.shareOrderRecords));
      // this.orderRecords = this.orderRecords.map((r) => {
      //   r.deliveryCode = this.deliveries[0];
      //   return r;
      // });
      this.rowId = Math.max(...this.orderRecords.map((r) => r.rowId)) + 1;
    }
    this.calcTotalItem();
    if (this.initialOrders) {
      this.addCart(this.initialOrders);
    }
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 200 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  filters: { comma },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 200 + "px";
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    addCart(products) {
      if (Array.isArray(products)) {
        // グリッドに追加
        const currentNumber = this.orderRecords.length;
        var orders = [];
        products.forEach((product, index) => {
          var order = { ...product };
          order.number = currentNumber + index + 1;
          order.rowId = this.rowId;
          order.deliveryCode = this.deliveries[0].value;
          this.rowId++;
          orders.push(order);
          this.orderRecords.push(order);
          this.$emit("onSync", this.orderRecords);
        });
        this.gridOptions.api.applyTransaction({ add: orders });
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      }
    },
    onCellValueChanged(params) {
      if (params.colDef.field == "orderQuantity") {
        this.calcTotalItem();
      }
    },
    calcTotalItem() {
      this.orderQuantityTotal = 0;
      this.orderUnitPriceTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.orderQuantityTotal += row.orderQuantity ? Number(row.orderQuantity) : 0;
        this.orderUnitPriceTotal +=
          row.unitPrice && row.orderQuantity ? Number(row.unitPrice) * Number(row.orderQuantity) : 0;
      }
    },
    onCloseClick() {
      this.$emit("onClose", this.orderRecords);
    },
    async onSubmitClick() {
      this.gridOptions.api.stopEditing(false);
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.gridOptions.api.forEachNode((record) => {
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            number: record.data.number,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          const updateModel = {
            orders: requestRecords,
          };
          const response = await this.$store.dispatch("order/entry", updateModel);
          let error = response.data?.header;
          let createRows = [];
          let updateOrderRecords = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  var errorRecord = this.orderRecords.find((record) => record.number == key);
                  this.errorRows.push({
                    number: key,
                    errorMessage: error.messages[key],
                  });
                  updateOrderRecords.push(errorRecord);
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 登録に成功したデータは一覧(注文表から削除する)
                // エラーにないデータを抽出
                // 一覧部の更新分のレコード設定
                this.gridOptions.api.forEachNode((row) => {
                  let isError = this.errorRows.some((r) => r.number == row.data.number);
                  if (!isError) {
                    createRows.push(row);
                  }
                });

                // 登録した分を削除(エラーがある場合のみ)
                if (createRows.length > 0) {
                  this.gridOptions.api.applyTransaction({ remove: createRows });
                }

                // 注文票をエラーデータのみにする(成功したデータは削除)
                this.orderRecords = updateOrderRecords;

                this.$refs.createErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`発注の登録処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.orderRecords = updateOrderRecords;
                this.rowId = 1;
                this.$dialog.notify.info(`発注を登録しました(${response.data.contents.directionNumber})`, {
                  timeout: 2300,
                });
                this.$emit("onSubmit");
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ProductCart::onSubmitClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.createErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`発注データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    async onRemoveClick() {
      const ok = await this.$dialog.confirm({ title: "注文票", text: `選択商品を注文票から削除します` });
      if (ok) {
        // グリッドから削除
        this.gridOptions.api.applyTransaction({ remove: this.selectionRows });
        let rowData = [];
        this.gridOptions.api.forEachNode((record, index) => {
          record.data.number = index + 1;
          rowData.push(record.data);
        });
        this.gridOptions.api.applyTransaction({ update: rowData });
        // カートから削除
        this.orderRecords = rowData;
        this.$dialog.notify.info(`選択商品を注文票から削除しました`, { timeout: 2300 });
      }
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 数量
      this.setValidMessage(this.rules.required(row.orderQuantity), "数量", messages);
      this.setValidMessage(this.rules.isNumber(row.orderQuantity), "数量", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.orderQuantity), "数量", messages);
      this.setValidMessage(this.orderQuantityRurle(row.orderQuantity, row.orderUnit), "数量", messages);
      // 配送CD
      this.setValidMessage(this.rules.required(row.deliveryCode), "配送CD", messages);
      // 取引先専用番号
      this.setValidMessage(this.rules.maxLength(64)(row.partnerPurchaseNo), "取引先専用番号", messages);
      // 取引先専用明細番号
      this.setValidMessage(this.rules.isNumber(row.partnerDetailNo), "取引先専用明細番号", messages);
      this.setValidMessage(this.rules.maxLength(6)(row.partnerDetailNo), "取引先専用明細番号", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    requestFormat(row) {
      return {
        productCode: row.productCd,
        orderQuantity: Number(row.orderQuantity),
        wholesaleRate: Number(row.orderRate),
        deliveryCode: row.deliveryCode,
        orderUnit: Boolean(row.forcast),
        partnerPurchaseNo: row.partnerPurchaseNo,
        partnerDetailNo: Number(row.partnerDetailNo),
      };
    },
    orderQuantityRurle(value, orderUnit) {
      if (value == null || value == "") return true;
      if (Number(value) % Number(orderUnit) !== 0) return `受注単位の倍数を入力してください`;
      return true;
    },
    reset() {
      this.addCart(this.initialOrders);
    },
  },
};
</script>
