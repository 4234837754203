<template>
  <v-card>
    <v-card-title>
      <template v-if="!inputModel.makerEntry">
        <span class="headline" v-if="inputModel.createNew"><v-icon>mdi-pencil-plus-outline</v-icon>商品登録</span>
        <span class="headline" v-if="!inputModel.createNew"><v-icon>mdi-pencil-outline</v-icon>商品編集</span>
      </template>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="productForm" lazy-validation>
        <section-block title="商品画像">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-file-input
                show-size
                label="画像ファイルをアップロードしてください"
                prepend-icon="mdi-image"
                @change="onImagePicked"
                filled
                chips
                small-chips
                multiple
                v-model="uploadFiles"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <simple-file-drop multiple @input="onImagePicked($event, true)" style="height: 100px"></simple-file-drop>
            </v-col>
          </v-row>
          <v-row class="mt-3" dense v-if="previewImages.length + previewNewImages.length > 0">
            <span
              class="mr-2"
              style="position: relative; border-bottom: solid 1px #000"
              v-for="(img, idx) in previewImages"
              :key="img.id"
            >
              <v-img
                contain
                min-height="180"
                max-height="180"
                min-width="180"
                max-width="180"
                :lazy-src="img.url"
                :src="img.url"
              ></v-img>
              <v-btn absolute top right small outlined icon @click="onRemoveImageClick(idx, img)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </span>
            <span
              class="mr-2"
              style="position: relative; border-bottom: solid 1px #000"
              v-for="(img, idx) in previewNewImages"
              :key="img"
            >
              <v-img
                contain
                min-height="180"
                max-height="180"
                min-width="180"
                max-width="180"
                :lazy-src="img"
                :src="img"
              ></v-img>
              <v-btn absolute top right small outlined icon @click="onRemoveNewImageClick(idx, img)">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </span>
          </v-row>
        </section-block>
        <section-block title="基本情報" separator>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-select
                :items="constant.productTypes"
                label="商品区分"
                v-model="updateModel.productType"
                :rules="[rules.required]"
                dense
                filled
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <product-title-field
                v-model="updateModel.title"
                :value="updateModel.title"
                :rules="[rules.required, rules.maxByteLengthSjis(60)]"
              ></product-title-field>
            </v-col>
            <v-col cols="12" sm="4">
              <category-field
                v-model="updateModel.categoryId"
                :value="updateModel.categoryId"
                :rules="[rules.required]"
              ></category-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field
                label="JANCODE"
                v-model="updateModel.janCodeBox"
                :rules="[rules.required, rules.isNumber, rules.isJancode]"
                :error-messages="jancodeErrorMsg"
                @blur="onBlurJancode()"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-switch label="Box商品" v-model="updateModel.pcsFlg" finset dense></v-switch>
            </v-col>
            <v-col cols="12" sm="2" v-if="updateModel.pcsFlg">
              <v-text-field
                label="JANCODE(pcs)"
                v-model="updateModel.jancodePcs"
                :rules="[rules.validPscJan, rules.isNumber, rules.isJancode]"
                :error-messages="jancodePcsErrorMsg"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="商品名"
                v-model="updateModel.productName"
                :rules="[rules.required, rules.maxByteLengthSjis(60)]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="商品名カナ"
                :rules="[rules.maxLength(30)]"
                v-model="updateModel.productNameKana"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="4" v-if="isCafereoUser && inputModel.createNew">
              <corporation-field
                :group="constant.makerCorporationType"
                v-model="updateModel.corporateCode"
                :rules="[rules.required]"
              ></corporation-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="isCafereoUser && inputModel.createNew">
              <supplier-field
                v-model="updateModel.supplierCd"
                :corporate="updateModel.corporateCode"
                :rules="[rules.required]"
                disabled-no-data
              >
              </supplier-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="isCafereoUser && !inputModel.createNew">
              <v-text-field label="仕入先略称" v-model="updateModel.supplierCd" disabled filled dense></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="発売元"
                :rules="[rules.required, rules.maxLength(30)]"
                v-model="updateModel.salesAgencyName"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="販売元"
                :rules="[rules.required, rules.maxLength(30)]"
                v-model="updateModel.salesOriginName"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field
                label="上代"
                v-model.number="updateModel.retailPrice"
                :rules="[rules.required, rules.isNumber, rules.maxLength(8)]"
                @change="updateModel.retailPrice = stringToNumber($event)"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                :label="isCafereoUser ? '仕入価格' : '販売単価'"
                v-model.number="updateModel.purchasePrice"
                :rules="[rules.required, rules.isNumber, rules.maxLength(8)]"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                :label="isCafereoUser ? '仕入掛率(%)' : '掛率(%)'"
                v-model="updateModel.purchaseRate"
                :rules="[rules.required, rules.maxRateLength(3, 1)]"
                @change="updateModel.purchaseRate = stringToNumber($event)"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                label="消費税(%)"
                v-model="updateModel.consumptionTaxRate"
                :items="constant.consumptionTaxes"
                :rules="[rules.required]"
                filled
                dense
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-textarea
                label="商品説明"
                :rules="[rules.maxLength(300)]"
                v-model="updateModel.productDetail"
                filled
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <dp-date-picker
                type="date"
                label="発注締日"
                v-model="updateModel.orderClosingDate"
                :rules="[rules.required]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <dp-date-picker
                type="about"
                label="発売日"
                v-model="updateModel.releaseDate"
                :rules="[rules.required]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="パッケージサイズ(mm)"
                v-model="updateModel.packageSize"
                :rules="[rules.maxLength(25)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="PKGsizeW(cm)"
                v-model.number="updateModel.pkgSizeWidth"
                :rules="[rules.isDecimal, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.pkgSizeWidth = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="PKGsizeD(cm)"
                v-model.number="updateModel.pkgSizeDepth"
                :rules="[rules.isDecimal, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.pkgSizeDepth = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="PKGsizeH(cm)"
                v-model.number="updateModel.pkgSizeHeight"
                :rules="[rules.isDecimal, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.pkgSizeHeight = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="Box入数"
                v-model.number="updateModel.inBoxQuantity"
                :rules="[rules.required, rules.isNumber, rules.maxLength(8), rules.isMinNumber(1)]"
                @change="updateModel.inBoxQuantity = stringToNumber($event)"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </section-block>
        <section-block title="販売情報" separator>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-select
                :items="productStatuses"
                label="商品ステータス"
                v-model="updateModel.productStatus"
                dense
                filled
                disabled
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" v-if="isCafereoUser">
              <v-switch label="メーカー在庫あり" v-model="updateModel.stockStatus" inset dense></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="受注単位"
                v-model.number="updateModel.orderUnit"
                :rules="[rules.required, rules.isNumber, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.orderUnit = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                :label="isCafereoUser ? '発注単位' : '受注単位'"
                v-model.number="updateModel.unit"
                :rules="[rules.isNumber, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.unit = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="上限数"
                v-model.number="updateModel.maximumQuantity"
                :rules="[rules.isNumber, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.maximumQuantity = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <dp-date-picker
                type="datetime"
                label="情報解禁日時"
                :inteval="5"
                v-model="updateModel.banDatetime"
                :rules="[rules.required]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="カフェレオ締め日"
                v-model="updateModel.cafereoClosingDate"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </section-block>
        <section-block
          title="再販商品情報  （「★」は公開中の情報となります。公開されている商品の締め日まで再販の登録はできません。）"
          separator
          v-if="!inputModel.createNew"
        >
          <v-row>
            <v-col cols="12">
              <v-btn x-small fab dense :disabled="this.resaleAddDisabledFlg || disabledResaleAdd" @click="onAddResale">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="updateModel.resales">
            <v-col cols="12">
              <v-expansion-panels v-for="(resale, idx) in updateModel.resales" :key="resale.productCd" accordion tile>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span v-if="idx === 0">{{ resale.productCd == updateModel.productCd ? "★" : "" }}新商品情報</span>
                    <span v-if="idx !== 0"
                      >{{ resale.productCd == updateModel.productCd ? "★" : "" }}再販商品情報({{ idx }})</span
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="商品名"
                          v-model.number="resale.productName"
                          :rules="[rules.required, rules.maxByteLengthSjis(60)]"
                          filled
                          dense
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <v-textarea
                          label="商品説明"
                          v-model.number="resale.productDetail"
                          :rules="[rules.maxLength(300)]"
                          filled
                          dense
                          rows="1"
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <dp-date-picker
                          type="date"
                          label="発注締日"
                          v-model="resale.orderClosingDate"
                          :rules="[rules.required]"
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                          @blur="onBlurResaleCafereoClosingDate(idx)"
                        ></dp-date-picker>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <dp-date-picker
                          type="about"
                          label="発売日"
                          v-model="resale.releaseDate"
                          :rules="[rules.required]"
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                        ></dp-date-picker>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          label="上限数"
                          v-model.number="resale.maximumQuantity"
                          :rules="[rules.isNumber, rules.maxLength(8)]"
                          filled
                          dense
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                          @change="resale.maximumQuantity = stringToNumber($event)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <dp-date-picker
                          type="datetime"
                          label="情報解禁日時"
                          :inteval="5"
                          v-model="resale.banDatetime"
                          :rules="[rules.required]"
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                        ></dp-date-picker>
                      </v-col>
                      <v-col cols="12" sm="2" v-if="isCafereoUser">
                        <v-text-field
                          label="カフェレオ締め日"
                          v-model="resale.cafereoClosingDate"
                          filled
                          dense
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          label="上代"
                          v-model.number="resale.retailPrice"
                          :rules="[rules.required, rules.isNumber, rules.maxLength(8)]"
                          filled
                          dense
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                          @change="
                            resale.retailPrice = stringToNumber($event);
                            calcPurchasePrce(resale.retailPrice, resale.purchaseRate).then((val) => {
                              resale.purchasePrice = val;
                            });
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          label="仕入価格"
                          v-model.number="resale.purchasePrice"
                          :rules="[rules.isNumber, rules.maxLength(8)]"
                          filled
                          dense
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          :label="isCafereoUser ? '仕入掛率(%)' : '掛率(%)'"
                          v-model="resale.purchaseRate"
                          :rules="[rules.required, rules.maxRateLength(3, 1)]"
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                          @change="
                            resale.purchaseRate = stringToNumber($event);
                            calcPurchasePrce(resale.retailPrice, resale.purchaseRate).then((val) => {
                              resale.purchasePrice = val;
                            });
                          "
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          label="受注単位"
                          v-model.number="resale.orderUnit"
                          :rules="[rules.required, rules.isNumber, rules.maxLength(8)]"
                          filled
                          dense
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                          @change="resale.orderUnit = stringToNumber($event)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          label="発注単位"
                          v-model.number="resale.unit"
                          :rules="[rules.isNumber, rules.maxLength(8)]"
                          filled
                          dense
                          :disabled="
                            resale.productCd == updateModel.productCd ||
                            (resale.productStatus >= constant.baseProductStatus.ADDITIONALRECEPTION && !resale.new)
                          "
                          @change="resale.unit = stringToNumber($event)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </section-block>
        <section-block title="付加情報" separator>
          <v-row dense>
            <v-col cols="12" sm="3" v-if="isCafereoUser">
              <v-text-field
                label="付属商品JANCD"
                v-model="updateModel.accessoriesJanCode"
                :rules="[rules.isJancode]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="CT中Box入数"
                v-model.number="updateModel.inCtBoxQuantity"
                :rules="[rules.isNumber, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.inCtBoxQuantity = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="isCafereoUser">
              <v-text-field
                label="CT中pcs入数"
                v-model.number="updateModel.inCtPcsQuantity"
                :rules="[rules.isNumber, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.inCtPcsQuantity = stringToNumber($event)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field
                label="CTsizeW(mm)"
                v-model.number="updateModel.ctSizeWidth"
                :rules="[rules.isDecimal, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.ctSizeWidth = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="CTsizeD(mm)"
                v-model.number="updateModel.ctSizeDepth"
                :rules="[rules.isDecimal, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.ctSizeDepth = stringToNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="CTsizeH(mm)"
                v-model.number="updateModel.ctSizeHeight"
                :rules="[rules.isDecimal, rules.maxLength(8)]"
                filled
                dense
                @change="updateModel.ctSizeHeight = stringToNumber($event)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-switch label="食品" v-model="updateModel.confectionery" inset dense></v-switch>
            </v-col>
            <v-col cols="12" sm="2">
              <dp-date-picker
                type="date"
                label="賞味期限"
                v-model="updateModel.sellBy"
                :rules="[rules.validSellBy]"
                :disabled="!updateModel.confectionery"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                label="原産国"
                v-model="updateModel.countryOfOrigin"
                :rules="[rules.maxLength(30)]"
                inset
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-switch label="初回締後追加不可" v-model="updateModel.nonAdded" inset dense></v-switch>
            </v-col>
            <v-col cols="12" sm="2">
              <v-switch label="危険物" v-model="updateModel.hazardousMaterial" inset dense></v-switch>
            </v-col>
            <v-col cols="12" sm="2">
              <v-switch label="飛行機検査不可" v-model="updateModel.airplaneInspection" inset dense></v-switch>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="原材料"
                v-model="updateModel.rawMaterials"
                :rules="[rules.maxLength(30)]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                label="コピーライト"
                v-model="updateModel.copyright"
                :rules="[rules.required, rules.maxLength(300)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-textarea
                :label="labelMakerRemarks"
                v-model="updateModel.makerRemarks"
                :rules="[rules.maxLength(100)]"
                filled
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </section-block>
        <section-block title="営業情報" separator v-if="isCafereoUser">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-select
                :items="constant.productRanks"
                label="商品ランク"
                v-model="updateModel.productRank"
                :rules="[rules.required]"
                dense
                filled
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="承認者" v-model="updateModel.approvalUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="承認日時"
                v-model="updateModel.approvalDatetime"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-textarea
                label="Cafereo備考"
                v-model="updateModel.cafereoRemarks"
                :rules="[rules.maxLength(100)]"
                filled
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </section-block>
        <section-block title="販売店公開範囲" v-if="isCafereoUser" separator>
          <v-row dense>
            <v-col cols="12">
              <v-radio-group v-model="updateModel.scopeType" row>
                <v-radio
                  :label="scope.text"
                  :value="scope.value"
                  v-for="scope in constant.scopeTypes"
                  :key="scope.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense v-if="updateModel.scopeType === 2">
            <v-col cols="12" sm="3">
              <v-btn v-model="updateModel.tohoLimited" :disabled="btnDisabled" @click="onAppendTohoLimited" dense
                >東方のため流通限定</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense v-if="updateModel.scopeType === 3">
            <v-col cols="12" sm="3">
              <v-btn
                v-model="updateModel.overseasImpossible"
                @click="onAppendOverseasImpossible"
                :disabled="btnDisabled"
                dense
                >海外不可</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense v-if="updateModel.scopeType !== 1">
            <v-col cols="12">
              <v-autocomplete
                :items="scopeCustomerCorporations"
                item-text="corporationName"
                item-value="corporateCode"
                label="会社名"
                dense
                filled
                multiple
                v-model="updateModel.scopeCorporateCodes"
                attach
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense v-if="updateModel.scopeType !== 1">
            <v-col cols="12" style="height: 400px">
              <ag-grid-vue
                id="ScopedCustomerSelector"
                class="ag-theme-alpine"
                :gridOptions="scopeGridOptions"
                :rowData="scopedCustomerRecords"
                style="height: 100%"
              ></ag-grid-vue>
            </v-col>
          </v-row>
        </section-block>
        <section-block title="履歴" separator v-if="!inputModel.createNew">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="更新日時" v-model="updateModel.updateDate" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="更新者" v-model="updateModel.updateUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録日時" v-model="updateModel.createDate" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録者" v-model="updateModel.createUser" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </section-block>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" v-if="!isMakerUser || !inputModel.createNew" @click="onCanselClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="inputModel.createNew" :disabled="btnDisabled">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!inputModel.createNew" :disabled="btnDisabled">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import ProductTypes from "../../consts/ProductTypes";
import ProductRanks from "../../consts/ProductRanks";
import ScopeTypes from "../../consts/ScopeTypes";
import CorporationTypes from "../../consts/CorporationTypes";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import MakerProductStatus from "../../consts/productStatus/MakerProductStatus";
import BaseProductStatus from "../../consts/productStatus/BaseProductStatus";
import FileUtils from "../../utils/FileUtils";
import ConvertUtils from "../../utils/ConvertUtils";
import SectionBlock from "../common/SectionBlock.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import CorporationField from "../../components/common/fields/CorporationField.vue";
import SupplierField from "../../components/common/fields/SupplierField.vue";
import CategoryField from "../../components/common/fields/CategoryField.vue";
import ProductTitleField from "../../components/common/fields/ProductTitleField.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "ProductEntry",
  props: ["inputModel", "vendorCorporations"],
  components: {
    AgGridVue,
    SectionBlock,
    SimpleFileDrop,
    CorporationField,
    SupplierField,
    CategoryField,
    ProductTitleField,
  },
  data() {
    const self = this;
    return {
      stringToNumber: ConvertUtils.stringToNumber,
      constant: {
        productTypes: ProductTypes.all(),
        productRanks: ProductRanks.all(),
        scopeTypes: ScopeTypes.all(),
        consumptionTaxes: [0, 8, 10],
        makerCorporationType: CorporationTypes.MAKER,
        baseProductStatus: BaseProductStatus,
      },
      serviceStting: this.$store.getters["security/serviceSetting"],
      uploadFiles: [],
      previewImages: [],
      removedImages: [],
      previewNewImages: [],
      scopeGridOptions: {
        defaultColDef: {
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          suppressSizeToFit: true,
        },
        columnDefs: [
          {
            headerName: "",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            width: 50,
          },
          { headerName: "法人名", field: "corporationName", width: 300 },
          { headerName: "取引先名", field: "customerName", width: 300 },
        ],
        alwaysShowHorizontalScroll: true,
        suppressCellSelection: true,
        rowSelection: "multiple",
        rowMultiSelectWithClick: true,
        pagination: true,
        paginationPageSize: null,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.customerCd,
        onRowDataChanged: (event) => {
          // 海外不可ボタンなどの自動チェック処理
          event.api.forEachNode((node) => {
            node.setSelected(false);
            for (let i = 0; i < self.updateModel.scopedCustomers.length; i++) {
              if (node.data.customerCd == self.updateModel.scopedCustomers[i]) node.setSelected(true);
            }
          });
        },
        onRowSelected(event) {
          if (event.node.isSelected()) {
            if (!self.updateModel.scopedCustomers.includes(event.data.customerCd)) {
              self.updateModel.scopedCustomers.push(event.data.customerCd);
            }
          } else {
            var index = self.updateModel.scopedCustomers.indexOf(event.data.customerCd);
            self.updateModel.scopedCustomers.splice(index, 1);
          }
        },
      },
      scopeCustomerCorporations: [],
      scopedCustomerRecords: [],
      updateModel: null,
      defaultModel: {
        productCd: null,
        images: [],
        productType: 1,
        title: null,
        categoryId: null,
        jancode: null,
        boxProduct: true,
        pcsFlg: false,
        jancodePcs: null,
        productName: null,
        productNameKana: null,
        supplierCd: null,
        salesAgencyName: null,
        salesOriginName: null,
        retailPrice: null,
        purchasePrice: null,
        purchaseRate: null,
        consumptionTaxRate: null,
        productDetail: null,
        orderClosingDate: null,
        releaseDate: null,
        packageSize: null,
        pkgSizeWidth: null,
        pkgSizeDepth: null,
        pkgSizeHeight: null,
        inBoxQuantity: 1,
        stockStatus: false,
        orderUnit: null,
        maximumQuantity: null,
        banDatetime: null,
        cafereoClosingDate: null,
        accessoriesJanCode: null,
        inCtBoxQuantity: null,
        inCtPcsQuantity: null,
        ctSizeWidth: null,
        ctSizeDepth: null,
        ctSizeHeight: null,
        confectionery: false,
        sellBy: null,
        nonAdded: false,
        hazardousMaterial: false,
        airplaneInspection: false,
        countryOfOrigin: null,
        rawMaterials: null,
        makerRemarks: null,
        productRank: null,
        cafereoRemarks: null,
        unit: 1,
        scopeType: 1,
        scopedCustomers: [],
        scopeCorporateCodes: [],
        resales: null,
      },
      btnDisabled: false,
      resaleAddDisabledFlg: false,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isDecimal: Validation.isDecimal,
        isJancode: Validation.isJancode,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        maxRateLength: Validation.maxRateLength,
        validPscJan: (value) => this.validPscJan(value),
        validSellBy: (value) => this.validSellBy(value),
        isMinNumber: Validation.isMinNumber,
      },
      jancodeErrorMsg: "",
      jancodePcsErrorMsg: "",
      dialogClosing: false,
      loading: false,
    };
  },
  beforeMount() {
    this.scopeGridOptions.paginationPageSize = this.globalPageSize;
    this.init(this.inputModel);
  },
  computed: {
    productStatuses() {
      if (this.isMakerUser) {
        return MakerProductStatus.all();
      } else {
        return CafereoProductStatus.all();
      }
    },
    entryCorporationType() {
      if (this.isCafereoUser) {
        return CorporationTypes.CAFEREO;
      } else if (this.isMakerUser) {
        return CorporationTypes.MAKER;
      }
      return null;
    },
    isLoading() {
      //return this.$store.getters["ui/isLoading"];
      return this.loading;
    },
    disabledResaleAdd() {
      let ret = false;
      if (this.updateModel.resales) {
        // 同一jancodeで1つでも発注締め以前のステータスがあれば再販は不可
        ret = this.updateModel.resales.some((elm) => elm.productStatus < BaseProductStatus.PURCHASECLOSED);
        if (ret) return ret;

        // 同一jancodeで全てで取扱不可のステータスの場合、再販は不可（新規商品が取扱不可の場合、再販は不可な仕様のため）
        ret = this.updateModel.resales.every((elm) => elm.productStatus == BaseProductStatus.NOTAVAILABLE);
      }
      return ret;
    },
    labelMakerRemarks() {
      let ret = "メーカー備考";
      if (this.isMakerUser) {
        ret = "備考";
      }
      return ret;
    },
  },
  watch: {
    inputModel(inputModel) {
      if (this.$refs.productForm) this.$refs.productForm.resetValidation();
      this.initUpdateModel(inputModel);
    },
    async "updateModel.scopeCorporateCodes"(value) {
      if (value && this.isCafereoUser) {
        await this.findCustomers(value);
        //グリッドから消された取引先の選択は外す
        let deleteList = [];
        this.updateModel.scopedCustomers.forEach((e) => {
          let deleteFlg = true;
          this.scopeGridOptions.api.forEachNode((node) => {
            if (node.data.customerCd == e) {
              deleteFlg = false;
            }
          });
          if (deleteFlg) {
            deleteList.push(e);
          }
        });
        this.updateModel.scopedCustomers = this.updateModel.scopedCustomers.filter(function (v) {
          return !deleteList.includes(v);
        });
      }
    },
    async "updateModel.retailPrice"(value) {
      let supplierCd = this.updateModel.supplierCd;
      if (this.isMakerUser) supplierCd = this.$store.getters["security/loggedInUser"].groupCode;
      if (!isNaN(Number(value)) && !isNaN(parseFloat(this.updateModel.purchaseRate)) && supplierCd) {
        const response = await this.$store.dispatch("product/calcPurchasePrce", {
          retailPrice: this.updateModel.retailPrice,
          purchaseRate: this.updateModel.purchaseRate,
          supplierCd: supplierCd,
        });
        this.updateModel.purchasePrice = response.data.contents.purchasePrice;
      } else {
        this.updateModel.purchasePrice = null;
      }
    },
    async "updateModel.purchaseRate"(value) {
      let supplierCd = this.updateModel.supplierCd;
      if (this.isMakerUser) supplierCd = this.$store.getters["security/loggedInUser"].groupCode;
      if (!isNaN(parseFloat(value)) && !isNaN(Number(this.updateModel.retailPrice)) && supplierCd) {
        const response = await this.$store.dispatch("product/calcPurchasePrce", {
          retailPrice: this.updateModel.retailPrice,
          purchaseRate: this.updateModel.purchaseRate,
          supplierCd: supplierCd,
        });
        this.updateModel.purchasePrice = response.data.contents.purchasePrice;
      } else {
        this.updateModel.purchasePrice = null;
      }
    },
    async "updateModel.supplierCd"(value) {
      if (value && !isNaN(Number(this.updateModel.retailPrice)) && !isNaN(parseFloat(this.updateModel.purchaseRate))) {
        const response = await this.$store.dispatch("product/calcPurchasePrce", {
          retailPrice: this.updateModel.retailPrice,
          purchaseRate: this.updateModel.purchaseRate,
          supplierCd: this.updateModel.supplierCd,
        });
        this.updateModel.purchasePrice = response.data.contents.purchasePrice;
      } else {
        this.updateModel.purchasePrice = null;
      }
    },
    "updateModel.orderClosingDate"(value) {
      // TODO: APIを使用して計算？ 製造QA一覧No.96
      this.updateModel.cafereoClosingDate;
      if (value) {
        this.updateModel.cafereoClosingDate = moment(value, "YYYY-MM-DD").subtract(2, "days").format("YYYY/MM/DD");
      } else {
        this.updateModel.cafereoClosingDate = null;
      }
    },
    async "updateModel.scopeType"() {
      this.updateModel.scopeCorporateCodes = [];
      this.updateModel.scopedCustomers = [];
      await this.findCustomers(this.updateModel.scopeCorporateCodes);
    },
    async "updateModel.janCodeBox"(value) {
      this.jancodeErrorMsg = await this.validIsJancode(value);
    },
    async "updateModel.jancodePcs"(value) {
      this.jancodePcsErrorMsg = await this.validIsJancodePcs(value);
    },
    // "updateModel.resales": {
    //   handler: function(next, prev) {
    //     console.log("resales",next,prev);
    //   },
    //   deep: true
    // },
    globalPageSize(size) {
      this.scopeGridOptions.api.paginationSetPageSize(size);
    },
  },
  methods: {
    // ***** 商品画像関連 *****
    onImagePicked(files, dropped) {
      if (!dropped) {
        this.previewNewImages = [];
      }
      files.forEach(async (file) => {
        if (file.type.toLowerCase().indexOf("image/") !== 0) {
          this.$dialog.notify.error(`画像形式のみアップロード可能です(${file.name})`);
          return;
        }
        const [width, height] = await FileUtils.getImageSize(file);
        if (!(width >= 500 && width <= 1200 && height >= 500 && height <= 1200)) {
          this.$dialog.notify.error(
            `画像サイズは縦横500～1200Pixel以内にしてください(${file.name} / ${width}x${height})`
          );
          return;
        }
        if (dropped) {
          this.uploadFiles.push(file);
        }
        const image = await FileUtils.readAsDataURL(file);
        this.previewNewImages.push(image);
      });
    },
    onRemoveImageClick(index, image) {
      this.previewImages.splice(index, 1);
      this.removedImages.push(image.id);
    },
    onRemoveNewImageClick(index) {
      this.uploadFiles.splice(index, 1);
      this.previewNewImages.splice(index, 1);
    },
    async onBlurJancode() {
      // 画像取得
      this.previewImages.splice(0, this.previewImages.length);
      const response = await this.$store.dispatch("product/getImageFileInfo", { jancode: this.updateModel.janCodeBox });
      console.log(this.updateModel.janCodeBox, ":", response.data.contents);
      response.data.contents.images.forEach((row) => {
        this.previewImages.push({
          id: row.id,
          url: row.url + "?" + moment().format("YYYYMMDDHHmmSS"),
          name: row.physicalFileName,
        });
      });
    },

    async onBlurResalePurchasRate(idx) {
      const retailPrice = this.updateModel.resales[idx].retailPrice;
      const purchaseRate = this.updateModel.resales[idx].purchaseRate;
      const supplierCd = this.updateModel.resales[idx].supplierCd;
      if (retailPrice && purchaseRate) {
        const response = await this.$store.dispatch("product/calcPurchasePrce", {
          retailPrice: retailPrice,
          purchaseRate: purchaseRate,
          supplierCd: supplierCd,
        });
        this.updateModel.resales[idx].purchasePrice = response.data.contents.purchasePrice;
      } else {
        this.updateModel.resales[idx].purchasePrice = null;
      }
    },

    onBlurResaleCafereoClosingDate(idx) {
      const value = this.updateModel.resales[idx].orderClosingDate;
      if (value) {
        this.updateModel.resales[idx].cafereoClosingDate = moment(value, "YYYY-MM-DD")
          .subtract(2, "days")
          .format("YYYY/MM/DD");
      } else {
        this.updateModel.resales[idx].cafereoClosingDate = null;
      }
    },
    async calcPurchasePrce(retailPrice, purchaseRate) {
      let ret = null;
      let supplierCd = this.updateModel.supplierCd;
      if (this.isMakerUser) supplierCd = this.$store.getters["security/loggedInUser"].groupCode;
      if (!isNaN(Number(retailPrice)) && !isNaN(parseFloat(purchaseRate)) && supplierCd) {
        const response = await this.$store.dispatch("product/calcPurchasePrce", {
          retailPrice: retailPrice,
          purchaseRate: purchaseRate,
          supplierCd: supplierCd,
        });
        ret = response.data.contents.purchasePrice;
      }
      return ret;
    },
    // ***** ボタン関連 *****
    async onCreateClick() {
      this.btnDisabled = true;
      if (await this.validatePickupedImages()) {
        if (this.validate()) {
          try {
            this.loadingOnSelf();
            const entryModel = JSON.parse(JSON.stringify(this.updateModel));

            // 既存画像ファイルはそのまま設定
            this.previewImages.forEach((row) => {
              entryModel.images.push({ id: Number(row.id), name: row.name });
            });
            // アップロード後の物理ファイル名を登録用に設定
            this.uploadFiles.forEach((row) => {
              entryModel.images.push({ id: null, name: row.name });
            });

            entryModel.jancode = entryModel.janCodeBox;
            entryModel.boxProduct = !entryModel.pcsFlg;
            if (entryModel.boxProduct) entryModel.jancodePcs = "";
            entryModel.setProductCode = null;
            if (entryModel.orderClosingDate) {
              entryModel.orderClosingDate = moment(entryModel.orderClosingDate, "YYYY/MM/DD").format("YYYY-MM-DD");
            }
            if (entryModel.banDatetime) {
              entryModel.banDatetime = moment(entryModel.banDatetime, "YYYY/MM/DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
            }
            const response = await this.$store.dispatch("product/entry", entryModel);
            switch (response.data?.header.resultCode) {
              case ApiStatus.consts.SUCCESS:
                await this.$store.dispatch("filing/imageUpload", {
                  files: this.uploadFiles,
                  productImages: response.data.contents.productImages,
                });
                this.uploadFiles = [];
                this.$emit("onEntrySubmit", this.updateModel);
                this.$dialog.notify.info(`商品を登録しました (${entryModel.jancode})`, { timeout: 2300 });
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                var message = "";
                Object.keys(response.data?.header.messages).forEach(function (key) {
                  message += response.data?.header.messages[key].join("<br>");
                });
                if (message != "") {
                  this.$dialog.warning({
                    title: `商品登録`,
                    text: message,
                    actions: ["OK"],
                  });
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("ProductEntry::onCreateClick", error);
            this.apiRequestError(error);
          } finally {
            // フォーム内容リセット
            if (this.$refs.productForm) {
              this.$refs.productForm.reset();
              this.$refs.productForm.resetValidation();
              this.updateModel = JSON.parse(JSON.stringify(this.defaultModel));
            }
            this.loadingOffSelf();
          }
        }
      }
      this.btnDisabled = false;
    },

    async onUpdateClick() {
      this.btnDisabled = true;
      if (await this.validatePickupedImages()) {
        if (this.validate()) {
          let isSuccess = false;
          try {
            this.loadingOnSelf();
            const entryModel = JSON.parse(JSON.stringify(this.updateModel));

            // 既存画像ファイルはそのまま設定
            this.previewImages.forEach((row) => {
              entryModel.images.push({ id: Number(row.id), name: row.name });
            });
            // アップロード後の物理ファイル名を登録用に設定
            this.uploadFiles.forEach((row) => {
              entryModel.images.push({ id: null, name: row.name });
            });

            entryModel.jancode = entryModel.janCodeBox;
            entryModel.boxProduct = !entryModel.pcsFlg;
            if (entryModel.boxProduct) entryModel.jancodePcs = "";

            entryModel.setProductCode = null;
            if (entryModel.orderClosingDate) {
              entryModel.orderClosingDate = moment(entryModel.orderClosingDate, "YYYY/MM/DD").format("YYYY-MM-DD");
            }
            if (entryModel.banDatetime) {
              entryModel.banDatetime = moment(entryModel.banDatetime, "YYYY/MM/DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
            }
            if (entryModel.resales[entryModel.resales.length - 1]) {
              entryModel.resales[entryModel.resales.length - 1].orderClosingDate = moment(
                entryModel.resales[entryModel.resales.length - 1].orderClosingDate,
                "YYYY/MM/DD"
              ).format("YYYY-MM-DD");
              entryModel.resales[entryModel.resales.length - 1].banDatetime = moment(
                entryModel.resales[entryModel.resales.length - 1].banDatetime,
                "YYYY/MM/DD HH:mm"
              ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (entryModel.resales.length > 1) {
              entryModel.resales[entryModel.resales.length - 2].orderClosingDate = moment(
                entryModel.resales[entryModel.resales.length - 2].orderClosingDate,
                "YYYY/MM/DD"
              ).format("YYYY-MM-DD");
              entryModel.resales[entryModel.resales.length - 2].banDatetime = moment(
                entryModel.resales[entryModel.resales.length - 2].banDatetime,
                "YYYY/MM/DD HH:mm"
              ).format("YYYY-MM-DD HH:mm:ss");
            }
            const response = await this.$store.dispatch("product/update", entryModel);
            console.log(response.data);
            switch (response.data?.header.resultCode) {
              case ApiStatus.consts.SUCCESS:
                await this.$store.dispatch("filing/imageUpload", {
                  files: this.uploadFiles,
                  productImages: response.data.contents.productImages,
                  pushImageStartIdx: this.previewImages.length,
                });
                this.$emit("onEditSubmit", this.updateModel);
                isSuccess = true;
                this.$dialog.notify.info(`商品を更新しました (${entryModel.jancode})`, { timeout: 2300 });
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                var message = "";
                Object.keys(response.data?.header.messages).forEach(function (key) {
                  message += response.data?.header.messages[key].join("<br>");
                });
                if (message != "") {
                  this.$dialog.warning({
                    title: `商品編集`,
                    text: message,
                    actions: ["OK"],
                  });
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("ProductEntry::onUpdateClick", error);
            this.apiRequestError(error);
          } finally {
            this.dialogClosing = true;
            // フォーム内容リセット
            if (this.$refs.productForm && isSuccess) {
              this.$refs.productForm.reset();
              this.$refs.productForm.resetValidation();
              this.updateModel = {};
            }
            this.loadingOffSelf();
          }
        }
      }
      this.btnDisabled = false;
    },
    onCanselClick() {
      //if (this.$refs.productForm) {
      this.$refs.productForm.resetValidation();
      this.$refs.productForm.reset();
      // 値が残るとコンポーンネントのWatchの値が変わらないので表示されない
      this.updateModel = {};
      //}
      this.$emit("onDialogClose");
    },
    async validatePickupedImages() {
      let isValid = true;
      if (this.previewImages.length + this.uploadFiles.length > this.serviceStting.imageNumberLimit) {
        this.$dialog.notify.error(`アップロード画像の枚数制限は${this.serviceStting.imageNumberLimit}枚以下です。`);
        isValid = false;
      }
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const file = this.uploadFiles[i];
        if (file.type.toLowerCase().indexOf("image/") !== 0) {
          this.$dialog.notify.error(`画像形式のみアップロード可能です(${file.name})`);
          isValid = false;
          continue;
        }
        const [width, height] = await FileUtils.getImageSize(file);
        if (!(width >= 500 && width <= 1200 && height >= 500 && height <= 1200)) {
          this.$dialog.notify.error(
            `アップロード画像のサイズは下限500pixel ～ 上限1200pixelです。(${file.name} / ${width}x${height})`
          );
          isValid = false;
        }
      }
      return isValid;
    },
    async onAppendOverseasImpossible() {
      try {
        this.btnDisabled = true;
        this.loadingOnSelf();
        const response = await this.$store.dispatch("customer/overseasImpossible");
        this.disclosureRangeProc(response.data.contents.customers);
      } catch (error) {
        console.error("onAppendOverseasImpossible", error);
        this.apiRequestError(error);
      } finally {
        this.btnDisabled = false;
        this.loadingOffSelf();
      }
    },
    async onAppendTohoLimited() {
      try {
        this.btnDisabled = true;
        this.loadingOnSelf();
        const response = await this.$store.dispatch("customer/tohoLimited");
        this.disclosureRangeProc(response.data.contents.customers);
      } catch (error) {
        console.error("onAppendOverseasImpossible", error);
        this.apiRequestError(error);
      } finally {
        this.btnDisabled = false;
        this.loadingOffSelf();
      }
    },
    async disclosureRangeProc(customers) {
      // 更新モデルとグリッドに追加（チェックはonRowDataで実施）
      let corpChange = false;
      customers.forEach((row) => {
        if (!this.updateModel.scopedCustomers.includes(row.customerCd)) {
          this.updateModel.scopedCustomers.push(row.customerCd);
        }
        if (!this.updateModel.scopeCorporateCodes.includes(row.corporationCd)) {
          this.updateModel.scopeCorporateCodes.push(row.corporationCd);
          corpChange = true;
        }
      });
      if (!corpChange) {
        this.findCustomers(this.updateModel.scopeCorporateCodes);
      }
    },
    onAddResale() {
      //
      this.updateModel.resales.push({
        productCd: null,
        retailPrice: null,
        purchasePrice: null,
        purchaseRate: null,
        orderClosingDate: null,
        releaseDate: null,
        maximumQuantity: null,
        cafereoClosingDate: null,
        new: true,
      });
      this.resaleAddDisabledFlg = true;
    },
    // 初期化処理
    async init(inputModel) {
      //
      this.initUpdateModel(inputModel);

      if (this.$refs.productForm) this.$refs.productForm.resetValidation();
      if (this.isCafereoUser) {
        const params = { customerFlg: true };
        const response = await this.$store.dispatch("corporation/suggest", params);
        this.scopeCustomerCorporations = response.data.contents.corporations;
        // 公開範囲販売店の取得（この後はupdateModel.scopeCorporateCodesをwatchして更新）
        if (this.updateModel.corporateCode && this.updateModel.scopeCorporateCodes) {
          await this.findCustomers(this.updateModel.scopeCorporateCodes);
        }
      }
    },
    //
    async initUpdateModel(inputModel) {
      this.btnDisabled = false;
      this.previewImages = [];
      if (inputModel.createNew) {
        // 新規登録
        this.updateModel = JSON.parse(JSON.stringify(this.defaultModel));
      } else {
        // 編集
        inputModel.pcsFlg = !inputModel.boxProduct;
        this.updateModel = JSON.parse(JSON.stringify(inputModel));
        console.log("initUpdateModel1:", inputModel);
        if (this.updateModel.images == null) this.updateModel.images = [];
        let tmp = JSON.parse(this.updateModel.resales);
        let resales = [];
        tmp?.forEach((row) => {
          resales.push(this.toCamelCaseObject(row));
        });
        this.updateModel.resales = resales;

        // 画面表示用の設定
        const dateStr = moment().format("YYYYMMDDHHmmSS");
        this.updateModel.images.forEach((elm) => {
          const image = elm.split(",");
          this.previewImages.push({
            id: image[0],
            url: image[1] + "?" + dateStr,
            name: image[2],
          });
        });
        this.updateModel.images = [];

        // 公開範囲の編集
        let corpCds = [];
        if (this.updateModel.scopeType !== 1 && this.isCafereoUser) {
          const response = await this.$store.dispatch("customer/suggestPost", {
            customerCds: this.updateModel.scopedCustomers,
          });
          let customers = response.data.contents.customers;
          for (const elm of customers) {
            if (!corpCds.includes(elm.corporationCd)) {
              corpCds.push(elm.corporationCd);
            }
          }
          // 一度消えるため、ここでコピー
          this.updateModel.scopedCustomers = this.inputModel.scopedCustomers;
          this.updateModel.scopeCorporateCodes = corpCds;
        }
      }
      if (this.updateModel.orderClosingDate) {
        this.updateModel.orderClosingDate = moment(this.updateModel.orderClosingDate, "YYYY/MM/DD").format(
          "YYYY-MM-DD"
        );
      }
      if (this.updateModel.banDatetime) {
        this.updateModel.banDatetime = moment(this.updateModel.banDatetime, "YYYY/MM/DD HH:mm:ss").format(
          "YYYY-MM-DDTHH:mm"
        );
      }
    },
    // 公開範囲のグリッド更新
    async findCustomers(corporateCodes) {
      try {
        this.loadingOnSelf();
        this.scopedCustomerRecords = [];
        for (let i = 0; i < corporateCodes.length; i++) {
          const response = await this.$store.dispatch("customer/suggest", { CorporationCd: corporateCodes[i] });
          if (response.data.contents.customers)
            this.scopedCustomerRecords = this.scopedCustomerRecords.concat(response.data.contents.customers);
        }
      } catch (error) {
        console.error("UserEntry::findCustomers", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOffSelf();
      }
    },
    loadingOnSelf() {
      this.loading = true;
    },
    loadingOffSelf() {
      this.loading = false;
    },
    // ***** ユーティリティ *****
    toCamelCase(str) {
      return str
        .split("_")
        .map(function (word, index) {
          if (index === 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join("");
    },
    // スネークケースからキャメルケースに変換（オブジェクト）.
    toCamelCaseObject(obj) {
      const result = {};
      Object.keys(obj).forEach((key) => {
        result[this.toCamelCase(key)] = obj[key];
      });
      return result;
    },
    // ***** バリデーション関連 *****
    validate() {
      let isValid = this.$refs.productForm.validate();
      if (!isValid || this.jancodeErrorMsg !== "" || this.jancodePcsErrorMsg !== "") {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validPscJan() {
      if (!this.updateModel.pcsFlg && this.updateModel.boxProduct) return true;
      if (this.updateModel.jancodePcs == null || this.updateModel.jancodePcs.trim().length === 0)
        return "pcs有の時は必須入力項目です";
      return true;
    },
    validSellBy() {
      if (!this.updateModel.confectionery) return true;
      if (this.updateModel.sellBy == null || this.updateModel.sellBy.trim().length === 0)
        return "食品は必須入力項目です";
      return true;
    },
    async validIsJancode(value) {
      if (!value) return "";

      let res = await this.$store.dispatch("product/isJancode", {
        jancode: value,
        jancodePcs: null,
        productCd: this.updateModel.productCd,
      });
      if (res.data.contents.isJancode) {
        return "JANCODEが重複しています。";
      }
      return "";
    },
    async validIsJancodePcs(value) {
      if (!value) return "";

      let res = await this.$store.dispatch("product/isJancode", {
        jancode: null,
        jancodePcs: value,
        productCd: this.updateModel.productCd,
      });
      if (res.data.contents.isJancode) {
        return "JANCODEが重複しています。";
      }
      return "";
    },
  },
};
</script>
