<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row no-gutters>
      <v-col>
        <v-tabs v-model="tab" centered grow class="mt-2 mb-2">
          <v-tab>商品登録</v-tab>
          <v-tab>一括登録</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-items">
          <v-tab-item>
            <product-entry
              ref="productEntry"
              :inputModel="productModel"
              @onEntrySubmit="onEntrySubmit"
              @expand="percentDialog.width = $event ? '100%' : '800px'"
            >
            </product-entry>
          </v-tab-item>
          <v-tab-item>
            <product-import ref="productImport"> </product-import>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { getDisplayDetails } from "../../models/ag-grid/helpers";
import ProductEntry from "../../components/product/ProductEntry.vue";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import MakerProductStatus from "../../consts/productStatus/MakerProductStatus";
import VendorProductStatus from "../../consts/productStatus/VendorProductStatus";
import ProductImport from "../../components/product/ProductImport.vue";

export default {
  name: "NewProductEntry",
  components: { ProductEntry, ProductImport },
  data() {
    return {
      vendorCorporations: [],
      corporetionRateModel: [],
      tab: null,
      items: ["1", "2"],
      productModel: {
        createNew: true,
      },
      step: 1,
      file: null,
      ccnt: 0,
    };
  },
  computed: {
    selectedImageList() {
      if (!(this.listSelected ? this.imgSelected : this.selectedRow)) {
        return [];
      }
      let imageList = this.listSelected ? this.imgSelected.imageList : this.selectedRow.imageList;

      if (!imageList || imageList.length == 0) imageList = ["/samples/images/NoImage.png"];
      return imageList;
    },
    selectedDetails() {
      if (!(this.listSelected ? this.imgSelected : this.selectedRow)) {
        return [];
      }
      return getDisplayDetails(
        this.listSelected ? this.imgSelected.productCode : this.selectedRow.productCode,
        this.gridApi,
        this.columnApi
      );
    },
    productStatuses() {
      if (this.isMakerUser) {
        return MakerProductStatus.all();
      } else if (this.isVendorUser) {
        return VendorProductStatus.all();
      } else {
        return CafereoProductStatus.all();
      }
    },
  },

  methods: {
    async onEntrySubmit() {
      try {
        //this.loadingOn();
      } catch (error) {
        //this.loadingOff();
      }
    },
  },
  beforeMount() {},
};
</script>
