<template>
  <v-menu absolute bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn v-bind="attrs" v-on="on" icon small class="mx-2" :disabled="disabled">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :size="!nonZoom && hover ? 30 : 15"
                style="transition: all 0.1s 0s linear"
                v-bind="attrs"
                v-on="on"
                >{{ icon }}</v-icon
              >
            </template>
            <span>{{ text }}</span>
          </v-tooltip>
        </v-btn>
      </v-hover>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>
export default {
  name: "TooltipIconMenuButton",
  props: {
    icon: { type: String },
    text: { type: String },
    "non-zoom": { type: Boolean },
    disabled: { type: Boolean, default: false },
  },
};
</script>
