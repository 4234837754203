<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form :style="{ height: contentHeight }" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="Excelファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isExcel]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div :style="{ height: contentHeight }">
              <p>登録予定件数：{{ excelRecords.length }}件</p>
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="excelRecords"
                class="ag-theme-alpine"
                style="height: 98%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div :style="{ height: contentHeight }">
              <p>{{ excelRecords.length }}件の取り込みが完了しました。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="ASIN一括取込入力"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import XLSX from "xlsx";
import { AgGridVue } from "ag-grid-vue";
import Validation from "../../../libs/validation";
import { statuses as ApiStatus } from "../../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../../models/ag-grid/locales";
import { DateColumn } from "../../../models/ag-grid/columnTypes";
import DialogBar from "../../common/DialogBar.vue";
import SimpleFileDrop from "../../common/SimpleFileDrop.vue";
import ErrorGridDialog from "../../../components/common/ErrorGridDialog.vue";
import FileUtils from "../../../utils/FileUtils";

export default {
  name: "AsinImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  props: ["height"],
  data: function () {
    return {
      step: 1,
      file: null,
      errorColmuns: [
        { headerName: "行番号", field: "rowNumber" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      validImportForm: null,
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        releaseDate: Validation.releaseDate,
        isJancode: Validation.isJancode,
        isExcel: Validation.isExcel,
      },
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: true,
          editable: false,
        },
        columnTypes: {
          dpDateColumn: DateColumn,
        },
        columnDefs: [
          { headerName: "ASIN", field: "columnA", pinned: "left" },
          { headerName: "EAN", field: "columnC", pinned: "left" },
          { headerName: "Vendor Code", field: "columnB" },
          { headerName: "RDD【Release date delivery (発売日お届け)】", field: "columnD" },
          { headerName: "初回発注締め日(年/月/日)YYYY/MM/DD", field: "columnE", type: "dpDateColumn" },
          { headerName: "発売年月日(年/月/日)YYYY/MM/DD", field: "columnF", type: "dpDateColumn" },
          { headerName: "情報解禁日(年/月/日)YYYY/MM/DD", field: "columnG", type: "dpDateColumn" },
          { headerName: "予約上限数", field: "columnH" },
          { headerName: "商品状態", field: "columnI" },
          { headerName: "商品状態_[通信欄]", field: "columnJ" },
          { headerName: "旧商品ASIN", field: "columnK" },
          { headerName: "購入制限数量", field: "columnL" },
          { headerName: "商品タイプ", field: "columnM" },
          { headerName: "商品カテゴリ", field: "columnN" },
          { headerName: "商品サブカテゴリ", field: "columnO" },
          { headerName: "商品名", field: "columnP" },
          { headerName: "ブランド名", field: "columnQ" },
          { headerName: "メーカー名", field: "columnR" },
          { headerName: "品番・型番", field: "columnS" },
        ],
        rowSelection: false,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        pagination: false,
        localeText: AG_GRID_LOCALE_JA,
      },
      excelRecords: [],
      contentHeight: this.height,
    };
  },
  watch: {
    height(value) {
      this.contentHeight = value;
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const excelArrayBuffer = await FileUtils.readAsArrayBuffer(this.file);
          const excelFile = XLSX.readFile(excelArrayBuffer, { type: "buffer" });
          const sheetNames = excelFile.SheetNames;
          const excelText = excelFile.Sheets[sheetNames[0]];
          this.excelRecords = [];
          let index = 1;
          let maxRecords = excelText["!ref"].match(/:[A-Z+]([0-9]+)/)[1];
          console.log("AsinImport::excelText", excelText);
          console.log("AsinImport::maxRecords", maxRecords);
          for (let i = 3; i <= maxRecords; i++) {
            let rowData = "";
            for (let j = 1; j <= 20; j++) {
              rowData += excelText[`${FileUtils.toStringColIndex(j)}${i}`]
                ? excelText[`${FileUtils.toStringColIndex(j)}${i}`]
                : "";
            }
            if (rowData) {
              this.excelRecords.push({
                rowNumber: index,
                columnA: excelText[`A${i}`] ? excelText[`A${i}`].w : "",
                columnB: excelText[`B${i}`] ? excelText[`B${i}`].w : "",
                // columnC: excelText[`C${i}`] ? Number(excelText[`C${i}`].w) : "",
                columnC: excelText[`C${i}`] ? excelText[`C${i}`].w : "",
                columnD: excelText[`D${i}`] ? excelText[`D${i}`].w : "",
                columnE: excelText[`E${i}`] ? moment(new Date(excelText[`E${i}`].w)).format("YYYY/MM/DD") : "",
                columnF: excelText[`F${i}`] ? moment(new Date(excelText[`F${i}`].w)).format("YYYY/MM/DD") : "",
                columnG: excelText[`G${i}`] ? moment(new Date(excelText[`G${i}`].w)).format("YYYY/MM/DD") : "",
                // columnH: excelText[`H${i}`] ? Number(excelText[`H${i}`].w) : "",
                columnH: excelText[`H${i}`] ? excelText[`H${i}`].w.trim() : null,
                columnI: excelText[`I${i}`] ? excelText[`I${i}`].w : "",
                columnJ: excelText[`J${i}`] ? excelText[`J${i}`].w : "",
                columnK: excelText[`K${i}`] ? excelText[`K${i}`].w : "",
                columnL: excelText[`L${i}`] ? excelText[`L${i}`].w.trim() : null,
                //columnL: excelText[`L${i}`] ? Number(excelText[`L${i}`].w) : "",
                columnM: excelText[`M${i}`] ? excelText[`M${i}`].w : "",
                columnN: excelText[`N${i}`] ? excelText[`N${i}`].w : "",
                columnO: excelText[`O${i}`] ? excelText[`O${i}`].w : "",
                columnP: excelText[`P${i}`] ? excelText[`P${i}`].w : "",
                columnQ: excelText[`Q${i}`] ? excelText[`Q${i}`].w : "",
                columnR: excelText[`R${i}`] ? excelText[`R${i}`].w : "",
                columnS: excelText[`S${i}`] ? excelText[`S${i}`].w : "",
              });
              index++;
            }
          }
          if (this.validateRecords(this.excelRecords)) {
            this.step++;
          }
          console.log("AsinImport::excelRecords", this.excelRecords);
        } catch (error) {
          console.error("AsinImport::onImport", error);
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      for (let record of this.excelRecords) {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      }
      if (this.excelRecords.length == 0) {
        this.errorRows.push({
          rowNumber: null,
          errorMessage: ["登録データが存在しません。"],
        });
        isValid = false;
      }
      if (isValid) {
        try {
          this.errorRows = [];
          this.loadingOn();
          for (var row of this.excelRecords) {
            row.columnH = Number(row.columnH);
            row.columnL = Number(row.columnL);
          }
          var param = {
            products: requestRecords,
          };
          console.log("AsinImport::onSubmit::param", param);
          const response = await this.$store.dispatch("amazon/importAsin", param);
          console.log("AsinImport::onSubmit", this.response);
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importNumber = response.data.contents.importNumber;
              this.step++;
              this.$dialog.notify.info(`ASINの取り込みが完了しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (this.errorRows.length > 0) {
                this.$refs.importErrorGrid.open({ records: this.errorRows });
              } else {
                this.$refs.importErrorGrid.close();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("AsinImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // ASIN
      this.setValidMessage(this.rules.required(row.columnA), "ASIN", messages);
      this.setValidMessage(this.rules.maxLength(10)(row.columnA), "ASIN", messages);
      // // EAN
      this.setValidMessage(this.rules.required(row.columnC), "EAN", messages);
      this.setValidMessage(this.rules.isJancode(row.columnC), "EAN", messages);

      this.setValidMessage(this.rules.maxLength(100)(row.columnB), "Vendor Code", messages);
      this.setValidMessage(
        this.rules.maxLength(100)(row.columnD),
        "RDD【Release date delivery (発売日お届け)】",
        messages
      );
      this.setValidMessage(this.rules.maxLength(100)(row.columnE), "初回発注締め日(年/月/日)YYYY/MM/DD", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnF), "発売年月日(年/月/日)YYYY/MM/DD", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnG), "情報解禁日(年/月/日)YYYY/MM/DD", messages);
      this.setValidMessage(this.rules.isNumber(row.columnH), "予約上限数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.columnH), "予約上限数", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnI), "商品状態", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnJ), "商品状態_[通信欄]", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnK), "旧商品ASIN", messages);
      this.setValidMessage(this.rules.isNumber(row.columnL), "購入制限数量", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.columnL), "購入制限数量", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnM), "商品タイプ", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnN), "商品カテゴリ", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnO), "商品サブカテゴリ", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnP), "商品名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnQ), "ブランド名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnR), "メーカー名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.columnS), "品番・型番", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    parseFloatFormat(value) {
      if (value == null || value == "") return null;
      return parseFloat(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(value).format("YYYY/MM/DD");
    },
    isDate(value) {
      console.log("row.date,", value);
      if (value == null) return true;
      if (!moment(value, "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isDecimal(value) {
      if (value == null || value == "") return true;
      let stringArray = String(value).split(".");
      let before = stringArray[0];
      let after = stringArray[1];
      if (before.length >= 12) return "整数値を12桁以内で入力してください";
      if (after != null && after.length >= 4) return "少数値を4桁以内で入力してください";
      return true;
    },

    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    requestFormat(row) {
      return {
        columnA: this.stringFormat(row.columnA),
        columnB: this.stringFormat(row.columnB),
        columnC: this.stringFormat(row.columnC),
        columnD: this.stringFormat(row.columnD),
        columnE: this.stringDateFormat(row.columnE),
        columnF: this.stringDateFormat(row.columnF),
        columnG: this.stringDateFormat(row.columnG),
        columnH: this.numberFormat(row.columnH),
        columnI: this.stringFormat(row.columnI),
        columnJ: this.stringFormat(row.columnJ),
        columnK: this.stringFormat(row.columnK),
        columnL: this.numberFormat(row.columnL),
        columnM: this.stringFormat(row.columnM),
        columnN: this.stringFormat(row.columnN),
        columnO: this.stringFormat(row.columnO),
        columnP: this.stringFormat(row.columnP),
        columnQ: this.stringFormat(row.columnQ),
        columnR: this.stringFormat(row.columnR),
        columnS: this.stringFormat(row.columnS),
      };
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
